
@import "../../../assets/style-guide/src/libs/less/variables.less";
.conainer{
    width: ~"calc(100% - 20px)";
    margin: 0 auto;
    height: 50%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 60px;
}
.command__container{
    width: 120px;
    margin: 0 auto;
    
}
.listItem{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 0 auto;
    height: 50px;
    img{
        width: 50px;
    }
}
.loader{
    margin: auto;
}
:global{
    .welcome{
        width: 100%;
        background-color: #f07b49;
        
        height: 100%;
    }
}
.center{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.logo{
    width:10rem;
    height:3rem;
    background-image:url(../../../static/img/logo@2x.png);
    background-size:100%;
    background-repeat:no-repeat;
}



/**
 * Content Loader
 */
 .content {
    width: 26px;
    height: 26px;
    position: relative;
    top: 0;
    left: -12px;
    overflow: visible;
    z-index: 1;
  
    .orange {
      z-index:3;
      height:100%;
      width:100%;
      position: absolute;
      top:0;
      left:-25%;
      fill:white;
      overflow:visible;
  
      animation: scaleAnimation205 0.6s, colourAnimation 6s;
      animation-timing-function:cubic-bezier(0.3,0,0.3,1);
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
    .midGrey {
      z-index:2;
      height:100%;
      width:100%;
      position: absolute;
      top:0;
      left:45%;
      fill:white;
      overflow: visible;
  
      animation: scaleAnimation205 0.6s, colourAnimation 6s;
      animation-timing-function:cubic-bezier(0.3,0,0.3,1);
      animation-delay: 0.2s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
    .smallGrey {
      z-index:1;
      height:100%;
      width:100%;
      position: absolute;
      top:0;
      left:115%;
      fill:white;
      overflow:visible;
  
      animation: scaleAnimation205 0.6s, colourAnimation 6s;
      animation-timing-function:cubic-bezier(0.3,0,0.3,1);
      animation-delay: 0.4s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }
  
  @keyframes scaleAnimation205 {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes colourAnimation {
    0% {
      fill:white;
    }
    100% {
      fill:white;
    }
  }
  