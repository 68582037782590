@import "../../../assets/style-guide/src/libs/less/btc-font-mixins.less";
@import "../../../assets/style-guide/src/libs/less/variables.less";
.tabs {
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 3.75rem;
  margin-top: 10px;

  .tabButton {
    padding: 0 1.25rem;
    background-color: transparent;
    height: 2.75rem;
    font-size: 0.875rem;
    color: var(--base-color);
    font-weight: normal;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.active {
      background-color: #fff;
      color: var(--primary-text);
      font-weight: 600;
      box-shadow: @boxShadow;
    }
  }
}
.recomended {
  overflow: hidden;
  height: 100%;
  width: 100%;
  .header {
    padding: 12px 20px;
    .navbar {
      display: flex;
      height: 35px;
      .backbtn {
        vertical-align: text-bottom;
        cursor: pointer;
        &:before {
          content: "\F165";
          color: var(--secondary-text);
          font-family: "btc-font";
          font-size: 15px;
          margin-right: 10px;
        }
      }
      .searchcontainer {
        height: 100%;
        margin-left: 14px;
        flex: 1;
        .searchBlock {
          height: 35px;
          display: flex;
          position: relative;
          border-radius: 3px;
          margin-right: 10px;
          .input {
            background-color: #eee;
            flex: 1;
            border-radius: 3px;
            border: 1px;
            font-size: 13px;
            text-align: left;
            color: #777777;
            width: 100%;
            padding-left: 10px;
            &:focus {
              outline: none;
            }
          }
          .input:focus::placeholder {
            transform: translate(0, 5%);
          }
          .icon {
            border-radius: 3px;
            background-color: #eee;
            padding: 3px;
            width: 30px;
            padding-top: 7px;
            padding-left: 10px;
            cursor: pointer;
            &:before {
              content: "\f173";
              color: @base-color;
              font-family: "btc-font";
              font-size: 18px;
            }
          }
          .close {
            background-color: #eee;
            width: 30px;
            padding-top: 5px;
            padding-left: 10px;
            cursor: pointer;
            &:before {
              content: "\f110";
              color: #777777;
              font-family: "btc-font";
              font-size: 14px;
            }
          }
        }
      }
      .setting {
        margin-left: 8px;
        margin-top: 4px;
        &:before {
          content: "\F138";
          color: @secondary-text;
          font-family: "btc-font";
          font-size: 15px;
        }
      }
      .filterButton {
        width: 35px;
        height: 35px;
        background-color: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 0 !important;
        border: none;
        &.active {
          background-color: var(--base-color);
          color: var(--base-text);
        }
      }
    }
  }
  .body {
    height: ~"calc(100% - 110px)";
    background-color: white;
    overflow: hidden;
    padding: 0px 0px;
    display: flex;
    flex-flow: column;
    .title {
      height: 18px;
      font-size: 13px;
      text-align: left;
      color: #222222;
      padding: 0px 20px;
    }
    .browse {
      background-color: white;
      height: ~"calc(100% - 49px)";
      overflow: visible;
      // padding: 0px;
      display: flex;
      flex-flow: column;
      // .title {
      //   height: 18px;
      //   font-size: 13px;
      //   text-align: left;
      //   color: #222222;
      //   padding: 0px 20px;
      // }
      .browseBodyInternal {
        float: 1;
        overflow-y: scroll;
        overflow-x: hidden;
        // margin-top: 5px;
        .item {
          font-size: small;
          padding: 0.5rem 1rem !important;
        }
        .noresult {
          text-align: center;
          font-size: 1rem;
          line-height: 1.4;
          color: #777777;
        }
        .noresult_message {
          text-align: center;
          margin: 0.5rem auto;
          font-size: 0.75rem;
          color: #777777;
        }
      }

      .browseFilesList {
        margin-top: 30px;
      }

      .selectAllWrapper {
        margin: 0 1rem;
        padding-top: 0.25rem;
        display: flex;
        position: absolute;
        left: 4px;
        flex-direction: row-reverse; /* Reverse the order of items */
      }

      .selectAllWrapper div > label {
        font-size: small;
      }

      .crumbWrapper {
        padding: 0rem 20px;
        .crumbs {
          display: flex;
          > li {
            min-width: 3rem;
            flex: 0 1 auto;

            &:first-child {
              flex: 0 0 auto;
            }
            &:nth-child(4) {
              flex: 1 1 auto;
            }
          }
        }
      }
    }
    .bodyinternal {
      float: 1;
      overflow: scroll;
      overflow-x: hidden;
      margin-top: 5px;
      height: 100%;
      .noresult {
        text-align: center;
        font-size: 1rem;
        line-height: 1.4;
        color: #777777;
      }
      .noresult_message {
        text-align: center;
        margin: 0.5rem auto;
        font-size: 0.75rem;
        color: #777777;
      }
    }
  }
  .footer {
    height: 49px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #dddddd;
    display: flex;
    justify-content: space-between;
    .action {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.8125rem !important;
    }
  }
  .showInfo {
    background-color: #4bca81;
    .title {
      text-align: center !important;
      width: 100%;
      font-size: 13px;
      color: #ffffff;
    }
    .oppCmdContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.onsearchfocus {
  border-bottom: solid 2px rgba(240, 123, 73, 0.7);
}
