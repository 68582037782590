/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2020 BigTinCan Mobile Pty Ltd
 * @author Olivia Mo <olivia.mo@bigtincan.com>
 */

@import '../../libs/less/btc-font-mixins.less';
@import '../../libs/less/utilities.less';
@import '../../libs/less/variables.less';

.ShareItem {
  display: flex;
  align-items: center;
  padding: 0.625rem;
  cursor: pointer;
  transition: background @timing-fast linear;

  &.isActive {
    background-color: lighten(#f26724, 35%);
    background-color: var(--light-base-color);
  }

  &:hover {
    background-color: lighten(#f26724, 35%);
    background-color: var(--light-base-color);

    .highlight {
      background-color: var(--base-color);
    }
  }

  .listShareDetails {
    margin-left: 0.625rem;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    width: 100%;

    p {
      margin: 0;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.gridItem {
  cursor: pointer;
}

.generalShareDetails {
  text-align: center;

  span {
    font-size: 0.8125rem;
    color: #777;
    color: var(--secondary-text);
  }
}

.textLeft {
  text-align: left;
}

.gridShareDetails {
  span:first-child {
    margin-right: 0.5rem;
  }
}

.ellipsis {
  .overflow-ellipsis();
}

.fileText {
  text-align: center;
  margin: 0.5rem 0 0.25rem 0;
}

.noMargin {
  margin: 0;
}

.fileNameIcon {
  margin-right: 0.4375rem;

  &:before {
    color: var(--base-color);
  }

  &.emailIcon::before {
    font-size: 1.375rem;
    padding-top: 3px;
  }

  &.linkIcon::before {
    font-size: 1rem;
  }

  &.roomIcon::before {
    font-size: 1.3rem;
  }
}

.expiredTag {
  display: flex;
  align-items: center;
  height: 1.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  color: var(--primary-color);
  background-color: #d1d1d6;
}

.fileNameWrapper {
  display: flex;
  align-items: center;
  max-width: 15rem;

  .fileName {
    flex: 1;
  }

  .highlight {
    background-color: var(--light-base-color);
  }
}
