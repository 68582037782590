/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/btc-font-mixins.less';
@import '../../libs/less/utilities.less';

@badgeSpacing: 8px;

.StoryBadges {
  width: 100%;
  height: 100%;
  position: relative;
  color: #fff;
  font-size: 90%;
}

.topActive {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.3) 0%,
    transparent 40%
  );
}
.bottomActive {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 0%, transparent 40%);
}
.topAndBottomActive {
  background: rgba(0, 0, 0, 0.3);
}

.badgeLabel {
  position: absolute;
  top: @badgeSpacing;
  left: @badgeSpacing;
  padding: 0 0.25rem;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 90%;
  color: #fff;
  font-weight: 700;
  max-width: 50%;
  text-align: left;
  .overflow-ellipsis();
}
.counts {
  position: absolute;
  top: @badgeSpacing;
  right: @badgeSpacing;

  > span {
    padding-left: 0.5rem;

    &:before {
      padding-right: 0.35rem;
    }
  }
}
.ratingCount {
  // likeCount
  .icon-like(16px);

  &.isLiked {
    .icon-like-fill(16px);
  }
}
.commentCount {
  .icon-comment-fill(16px);
}

.leftIcons {
  position: absolute;
  bottom: @badgeSpacing;
  left: @badgeSpacing;

  > span {
    vertical-align: middle;
    padding-right: 0.5rem;
    display: inline-block;
    min-width: 1.5rem;
  }
}
.rightIcons {
  position: absolute;
  bottom: @badgeSpacing;
  right: @badgeSpacing;

  > span {
    vertical-align: middle;
    padding-left: 0.5rem;
    display: inline-block;
    min-width: 1.5rem;
  }
}

.quicklinkIcon {
  .icon-quicklink-fill(16px);
}
.quickfileIcon {
  .icon-file(16px);
}
.locationIcon {
  .icon-location(16px);
}
.protectedIcon {
  .icon-lock(16px);
}
.bookmarkIcon {
  .icon-bookmark-fill(16px);
}
