/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Jason Huang <jason.huang@bigtincan.com>
 */

@import '../../../libs/less/variables.less';
@import '../../../libs/less/btc-font-mixins.less';

@custom-divider-color: #555555;

.BroadcastControl {
  background-color: black;
  width: 22rem;
  min-width: 400px;
  height: 100%;
  padding: 3rem 1rem 20px 5px;
  //background-color: white;
  //border-radius:4px;
  //color:black;
  //z-index:2;
  //width: 240px;
  //position:absolute;
  //top:3rem;
  //left:calc(50% - 1rem - 17.5px);
  //transform: translateX(-70%);
  //white-space: nowrap;
}

.BroadcastContainer {
  background-color: @primaryText;
  margin-top: 21px;
  height: calc(~'100%' - 21px);
  border-radius: 4px;
  z-index: 2;
  position: relative;
}

.title {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  color: @baseColor;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: 0.5px solid @custom-divider-color;
  border-bottom-color: @custom-divider-color;
  font-weight: 600;
}

.content {
  padding: 20px;
  border-bottom: 0.5px solid @custom-divider-color;
  border-bottom-color: @custom-divider-color;

  button {
    width: 100%;
  }
}

.startFooter {
  display: flex;
  justify-content: center;
  padding: 15px;
  border-bottom: 0.5px solid @custom-divider-color;
  border-bottom-color: @custom-divider-color;
  flex-direction: column;

  label {
    font-size: 14px;
  }
}

.stopFooter {
  display: flex;
  justify-content: center;
  padding: 10px 20px 20px 20px;
  border-bottom: 0.5px solid @custom-divider-color;
  border-bottom-color: @custom-divider-color;

  label {
    font-size: 14px;
  }

  > div {
    width: 100%;

    > button {
      width: 100%;
    }
  }
}

.guestInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.shareLinkLabel {
  text-align: center;
  font-size: 13px;
  padding: 10px 0px;
  color: @primaryText;
}

.checkboxPasswordProtected {
  align-self: center;
}

.inputBox {
  input {
    margin: 20px 0px 0px 0px !important;
  }
}

.guestNum {
  font-size: 15px;
  color: @secondaryText;
}

.guestView {
  font-size: 13px;
  color: @baseColor;
  cursor: pointer;
}

.hidden {
  display: none;
}

.inviteGuestsTitle {
  color: @secondaryText;
  text-align: center;
  padding-bottom: 10px;
}

.desc {
  color: @secondaryText;
  text-align: center;
  padding: 0.8rem 0px;
  font-size: 0.8rem;
}
