/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2020 BigTinCan Mobile Pty Ltd
 * @author Olivia Mo <olivia.mo@bigtincan.com>
 */

@import '../../libs/less/variables.less';

.noShrink {
  flex-shrink: 0;
}

.thumbnail {
  background-position: center;
  background-repeat: no-repeat;
}

.thumbnailWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: #fff;
  color: var(--background-color);

  span {
    font-size: 0.8125rem;
    text-align: center;
  }
}
