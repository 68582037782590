/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2017 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

 @import "../../../assets/style-guide/src/libs/less/btc-font-mixins.less";
 @import "../../../assets/style-guide/src/libs/less/variables.less";
 
 @iconSize: 20px;
 @halfToolbar: 23px;
 
 .fullFixed() {
   position: absolute !important;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
 }
 
 .AudioVideo {
   display:flex;
   position:relative;
   background:#fff;
   width:100%;
   height:100%;
 
   &:focus {
     outline:none;
   }
 }
 
 .inViewer {
   .fullFixed();
 }
 
 .isVideo {
   background:#000;
 }
 
 .audio, .video {
   margin:auto;
   max-width:100%;
   max-height:100%;
   overflow:hidden;
 
   > video {
     height:auto;
     width:auto;
     max-width:100%;
     max-height:100%;
   }
 }
 
 .audio {
   color:@divider-color;
   display:flex;
   justify-content:center;
   align-items:center;
   min-height:400px;
 }
 .audio.loaded {
   .icon-music(172px);
 
   &:before {
     margin-top:-@halfToolbar;  /* half height of toolbar */
   }
 }
 
 .video {
   > video {
     display:block;
     margin:-@halfToolbar auto 0;
 
     &::-webkit-media-controls {
       display:none;
     }
   }
 }
 
 .loading {
   .fullFixed();
 
   display: flex;
   justify-content: center;
   align-items: center;
 }
 
 .error {
   position: absolute;
   width: 100%;
   height:100%;
 }
 