/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../../libs/less/variables.less';

.fullFixed() {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.SpreadsheetViewer {
  height: 100%;
  width: 100%;

  &:focus {
    outline: none;
  }
  @media @mobile, @mobile-xs, @tablet {
    margin-top: 3.125rem;
  }
}

.inViewer {
  .fullFixed();
  overflow: hidden;

  @media @mobile-xs, @tablet, @mobile, @desktop {
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
  }
}

.frame {
  display: block;
}

.disablePointEvents {
  @media @mobile-xs, @tablet, @mobile {
    pointer-events: none;
  }
}
