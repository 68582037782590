/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/btc-font-mixins.less';
@import '../../libs/less/utilities.less';
@import '../../libs/less/variables.less';

.ShareRecipientItem {
  display: block;
  overflow: hidden;

  &.isActive {
    background-color: var(--light-base-color);
  }

  * {
    cursor: pointer !important;
  }
}

.listItem {
  align-items: center;
  transition: background @timing-fast linear;

  &.noLink {
    padding: 0.5rem;

    &:hover {
      background-color: @light-base-color;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
  }

  a {
    display: block;
    color: @primary-text;
    padding: 0.75rem;

    &:hover {
      text-decoration: none;
      background-color: @light-base-color;
    }
  }
}

.selected {
  background-color: @light-base-color;
}

.user {
  flex: 1 1 auto;
}

.emailUser {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.email {
  flex: 1 1 auto;
  padding-left: 0.5rem;
}

.viewed {
  .icon-eye(1rem);
  color: @secondary-text;
  font-size: 90%;

  &:before {
    color: @base-color;
    vertical-align: bottom;
  }

  strong {
    padding: 0 0.5rem;
  }
}

.download {
  color: @secondary-text;
  font-size: 90%;
  margin-left: 5rem;
}
