/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-starter
 * @copyright 2010-2017 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

/* Web App Shared variables */

@import "./_variables.less";
@import "./_reset.less";
@import "./_fonts.less";
@import "./_components.less";

/* Example App Global Styles */

body {
  height: 100%;
  font-family: "Open Sans";
  color: @color1;
  transition: background 150ms linear;
  font-family: "Open Sans";
  /* responsive font sizes */
  @media @mobile {
    font-size: 20px;
  }
  @media @tablet {
    font-size: 18px;
  }
  #root {
    position: relative;
    height: 100%;
  }
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  .view {
    padding: 14px 20px;
    width: 100%;
    height: 100%;
  }
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center_hr {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  width: 100%;
}

.rightarrowblock {
  // display: block;
  &:after {
    content: "\F153";
    color: #b8b8b8;
    // display: block;
    font-family: "btc-font";
    padding-top: 28px;
  }
}

.switch {
  border: 1px solid #ccc;
  width: 50px;
  height: 26px;
  border-radius: 13px;
  cursor: pointer;
  display: inline-block;
}

.switch-toggle {
  border: 1px solid #999;
  box-shadow: 1px 1px 1px #ccc;
  width: 25px;
  height: 24px;
  left: 0;
  border-radius: 12px;
  background: #fff;
  position: relative;
  transition: left 0.2s ease-in-out;
}

.switch.on {
  background: green;
}

.switch.on .switch-toggle {
  left: 23px;
}

.switch.disabled {
  cursor: not-allowed;
}

.switch.on {
  background: #4bca81;
}

.hideitem {
  display: none !important;
}

.loader {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rw-widget {
  background-clip: border-box;
  border: none;
  color: #333333;
  font-size: 1em;
  font-family: inherit;
  outline: none;
  position: relative;
  border-radius: 3px;
  border: 1px;
  background-color: #f5f5f5;
  width: 100%;
  font-size: 14px;
  text-align: left;
}

.rw-widget-container {
  background-color: #f5f5f5;
  border: #ccc 1px solid;
  border-radius: 4px;
}

.rw-input,
.rw-filter-input {
  color: #555555;
  padding: 0 0.857em;
  background-color: #f5f5f5;
  font-size: 14px;
  text-align: left;
  color: #222222;
  font-family: "Open Sans";
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.rw-widget-picker > .rw-select {
  border: none;
}

.rw-widget > .rw-widget-container {
  border: none;
}

.rw-i-calendar:before {
  color: #f07b49;
}

input::-ms-clear {
  display: none;
}

.rw-btn-select {
  vertical-align: text-bottom;
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-x: hidden;
  .btnlogout {
    font-size: 15px;
    width: "100%";
    height: "100%";
  }
}

.switch-toggle {
  height: 23.4px !important;
}
