/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../../libs/less/variables.less';

@containerWidth: 210px;

@pagesBg: #f8f8f8;
@pagesBorder: #ccc;

.PdfPages {
  width: @containerWidth;
  min-width: @containerWidth;
  height: 100%;
  padding-top: 15px;
  float: left;
  z-index: 1;
  overflow: auto;
  background: @pagesBg;
  border-right: solid 1px @dividerColor;
  border-right-color: var(--divider-color);
}

.pdfPagesHeader {
  height: 1.375rem;
  padding: 0 0.625rem;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.625rem;
  position: fixed;
  top: 0;
  left: 11.25rem;
  z-index: 100;

  .pdfPagesCloseBtn {
    &::before {
      color: var(--base-color);
    }
  }
}

.pdfThumbnail {
  margin: 0 auto;
  margin-bottom: 0.625rem;
  padding: 0;
  overflow: hidden;
  cursor: pointer;

  height: 12.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &:last-child > p {
    margin-bottom: 0;
  }

  img {
    display: block;
    max-width: 8.25rem;
    max-height: 10.625rem;
    width: 8.25rem;
    height: 10.6rem;
    margin: 0 auto;
    padding: 1px;
    border: solid 1px @pagesBorder;
    border-radius: 2px;
  }

  > p {
    margin: 0.35rem 0 0;
    text-align: center;
    color: @secondary-text;
    font-weight: bold;
  }
}

:global {
  .pdfThumbnailSelected {
    img {
      padding: 0;
      border: solid 2px @baseColor !important;
      border-color: var(--base-color) !important;
    }
    p {
      color: var(--base-color) !important;
    }
  }
}
