/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2016 BigTinCan Mobile Pty Ltd
 * @author Bhuvan Kakkar <bhuvan.kakkar@bigtincan.com>
 */

@import "../../less/_variables.less";

.searchFiltersWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.625rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  align-items: flex-start;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  flex-direction: row;

  .dropmenu {
    background: #eee;
    color: #000;
    border-radius: 0.25rem;
    display: flex;
    align-items: flex-start;
    margin-right: 0.5rem;
    padding: 0.375rem 0.5rem;

    > div {
      color: var(--primary-text);
    }
    .heading {
      transition: color @timing-fast ease-out;

      &:after {
        .btc-font();
        color: var(--base-color);
        content: "\f185"; // triangle
        font-size: 0.425rem;
        padding-left: 0.375rem;
      }
    }

    li {
      font-size: 100%;
      padding: 0.5rem 0.625rem !important;
      color: var(--primary-text);
      .commonSelectedClass {
        position: relative;
        width: auto;
        &:before {
          color: var(--base-color);
        }
      }

      .sortAscending {
        .icon-sort-ascending(1.1rem);
        .commonSelectedClass();
      }

      .sortDescending {
        .icon-sort-descending(1.1rem;);
        .commonSelectedClass();
      }
    }

    .item {
      display: flex;
      justify-content: space-between;
    }

    .iconChecked {
      .icon-tick-alt(0.75rem);
      color: var(--base-color);
    }

    .selectClearZone {
      position: absolute;
      right: 3rem;
      top: 2.6rem;
      z-index: 99;
      cursor: pointer;
      color: #999;

      .selectClear {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-close(1rem);
        &:before {
          font-weight: 600;
        }
      }

      &:hover {
        color: var(--base-color);
      }
    }
  }

  .clearFiltersBtn {
    // width: 10.625rem;
    margin-left: 2rem;
    margin-top: 0.25rem;
    text-align: right;
    border-color: transparent;
    color: var(--base-color) !important;
    background-color: var(--background-color) !important;
    padding: 0.05rem 0;
  }

  .doneButton {
    float: right;
    margin: 0.625rem 0;
  }
}

.dropMenuItemContainer {
  height: 35vh;
  overflow: scroll;
}
