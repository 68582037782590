/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/btc-font-mixins.less';
@import '../../libs/less/tooltips.less';
@import '../../libs/less/utilities.less';
@import '../../libs/less/variables.less';

.WebItem {
  &.isActive {
    background-color: var(--light-base-color);
  }
}

.listItem {
  align-items: center;
  transition: background @timing-fast linear;
  padding: 0.5rem;

  a {
    display: flex;
    align-items: center;
    color: @primary-text;
  }

  &:hover {
    text-decoration: none;
    background-color: @light-base-color;
  }
}

// Admin Listing items
.listView {
  //display: inline;
  width: 100%;
  .overflow-ellipsis();

  > span {
    text-align: left !important;
    margin: 0;
  }
}

.gridItem {
  .tooltip(s);
  display: inline-block;
  margin: 1rem;
  margin-bottom: 2rem;

  &:after {
    max-width: 250px;
    .overflow-ellipsis();
  }
}

.thumbnail() {
  background-color: #f5f5f5;
  position: relative;
  background-size: cover;
  font-size: 1.2rem;
  text-align: center;
  color: @primary-text;
  transition: all @timing-fast linear;

  &:before {
    color: #fff;
  }
}
.thumbnailPublic {
  &:hover {
    opacity: 0.7;
  }
}

.listThumbnail {
  .thumbnail();
  margin-right: 0.5rem;

  &:before {
    font-size: 32px;
    line-height: 1.45;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

.gridThumbnail {
  .thumbnail();

  &:before {
    font-size: 114px !important;
    line-height: 1.35 !important;
  }
}

.noThumb {
  display: inline-block;
  vertical-align: middle;

  .icon-web();
}

.name {
  color: @primary-text;
  display: block;
  text-align: center;
  margin: 0.5rem 0;
  transition: all @timing-fast linear;

  &:hover {
    opacity: 0.7;
  }
}

.url {
  color: @secondary-text;
  font-size: 90%;
  display: block;
  .overflow-ellipsis();
}

.dropMenu {
  position: absolute !important;
  top: 5px;
  right: 18px;
  z-index: 10;
  text-align: left;

  ul > li {
    font-size: 85%;
    //padding: 0.20rem 0.85rem !important;
  }
}

.overlayContainer {
  &:hover > .overlay {
    display: block;
  }
}

.overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.personal {
  position: relative;
  .icon-user-fill(90%, ~'after');

  &:after {
    margin-left: 0.6rem;
    color: @base-color;
  }
}
