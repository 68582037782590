/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2020 BigTinCan Mobile Pty Ltd
 * @author Olivia Mo <olivia.mo@bigtincan.com>
 */

@import '../../libs/less/btc-font-mixins.less';
@import '../../libs/less/utilities.less';
@import '../../libs/less/variables.less';

.storyItem {
  display: block;
  overflow: hidden;
  position: relative;
}

/* Grid Styles */
.gridItem {
  position: relative;
  .nameItem {
    text-align: center;
    color: #222;
    color: var(--primary-text);
    margin-top: 0.5rem;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.1875rem;
      > span {
        line-height: 1rem;
      }
    }

    span:last-child {
      color: @footNote-color;
      font-size: 0.75rem;
    }

    .storyBadge {
      color: #f66620;
      color: var(--base-color);
      margin-right: 0.5rem;
      font-weight: 600;
      font-size: 0.75rem;
    }
  }

  .name {
    display: block;
    .overflow-ellipsis();
    -webkit-box-orient: vertical;
    font-size: 0.875rem;
    line-height: 1.1875rem;
  }

  .badgeTitle {
    margin-right: 0.5rem;
    font-weight: 600;
    font-size: 0.75rem;
  }

  .author {
    .overflow-ellipsis();
  }

  .preview {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.9);
    color: var(--primary-text);
    padding: 1rem;
    font-size: 0.75rem;
    font-weight: 400;
    cursor: pointer;
    transition: opacity @timing-fast ease-in;
    opacity: 0;

    .excerpt {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 8rem;
      word-wrap: break-word;
    }

    .updated {
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      color: #777777;
    }

    .openQuicklinkBtn {
      position: absolute;
      bottom: 0.75rem;
      right: 0.75rem;
      background: transparent;
    }

    &:hover {
      opacity: 1;
    }
  }

  .quickEditBtn {
    background: transparent;
    transition: all @timing-fast linear;
    position: absolute;
    color: #ffffff;
    top: 0.75rem;
    right: 0.75rem;
    width: 1.125rem;
    height: 1.125rem;
    &:hover {
      color: var(--dark-base-color);
    }
  }
}

/* List Styles */
.listItem {
  align-items: center;
  transition: background @timing-fast linear;

  a {
    display: flex;
    align-items: center;
    color: @primary-text;
    padding: 0.5rem;

    &:hover {
      text-decoration: none;
      background-color: var(--light-base-color);
    }
  }

  .nameItem {
    flex: 1;
  }

  .badgeTitle {
    border-radius: 0.25rem;
    padding: 0.18rem 0.5rem;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1rem;
    margin-left: 0.625rem;
  }

  .fileCount {
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 1.125rem;
    color: #777777;
  }

  .buttonsAndCounts {
    flex: 0 1 auto;
    display: flex;
    align-items: center;

    > * {
      padding-left: 0.5rem;
    }
  }

  .badgeCounts {
    margin-right: 0.5rem;
    > span {
      padding-left: 0.5rem;

      &:before {
        padding-right: 0.35rem;
      }
    }
    .ratingCount {
      .icon-like(16px);
      &:before {
        color: #777777;
      }
      &.isLiked {
        .icon-like-fill(1rem);
      }
    }
    .commentCount {
      .icon-comment-fill(1rem);
      &:before {
        color: #777777;
      }
    }
  }

  .openQuicklinkBtn {
    margin-left: 0.5rem;
    background: transparent;

    &:hover {
      background-color: #ffffff !important;
    }
  }
  .quickEditBtn {
    margin-left: 0.5rem;

    &:hover {
      color: var(--dark-base-color);
    }
  }
}
