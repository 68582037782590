/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Rubenson Barrios <rubenson.barrios@bigtincan.com>
 */

@import '../../libs/less/btc-font-mixins.less';
@import '../../libs/less/variables.less';
@import '../../libs/less/utilities.less';

.ConfigurationBundleItem {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.showEdit {
  .name {
    position: relative;
    padding: 0;
  }
}

/* Thumbnail mixin */
.thumbnail() {
  text-align: center;
  background-size: cover;
  position: relative;
  flex: 0 0 auto;

  &:before {
    color: #fff;
  }

  // indicator icons
  > span {
    position: absolute;
    bottom: 0.25rem;
    color: #fff;
  }
}

/* List Styles */
.listItem {
  align-items: center;
  transition: background @timing-fast linear;
  padding: 0.5rem;

  &:hover {
    background-color: @light-base-color;
    cursor: pointer;
  }

  .wrapper {
    display: flex;
    align-items: center;
  }
}

.showIcon {
  .icon-package();
  &:before {
    position: absolute;
    top: 30%;
    color: @base-color;
  }

  > .wrapper {
    margin-left: 2.75rem;
  }
}

.selected {
  background-color: @light-base-color;
}

.info {
  flex: 1 1 auto;
  width: 72%;
}

.name {
  color: @primary-text;
  display: block;
  .overflow-ellipsis();
  width: 72%;
}

.note {
  color: @secondary-text;
  font-size: 90%;
  display: block;
  width: 76.5%;
  text-transform: capitalize;
  .overflow-ellipsis();
}

.edit {
  position: absolute;
  right: 1.75rem;
  top: 35%;
  padding: 0 1.2rem 0 0;

  .icon-edit-box(1.35rem);
  &:before {
    color: @base-color;
    cursor: pointer;
  }
}

.trashBtn {
  position: absolute;
  right: 1rem;
  top: 35%;

  .icon-trash(1.25rem);
  &:before {
    color: @base-color;
    cursor: pointer;
  }
}
