/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/btc-font-mixins.less';
@import '../../libs/less/variables.less';

@radioBorder: #888;

.Radio {
  label {
    .icon-circle-fill();
    display: inline-block;
    cursor: pointer;
    position: relative;
    color: @secondary-text;
  }

  label:before {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 2px;

    width: 1rem;
    height: 1rem;
    line-height: 1.85;
    margin-right: 0.5rem;
    text-align: center;

    font-size: 0.5rem;

    background-color: #fff;
    border-radius: 50%;
    border: solid 1px @radioBorder;
    color: #fff;

    transition: color @timing-fast ease-in-out;
  }

  /* hide <input> from view */
  input[type='radio'] {
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    border: none;
    appearance: none;
  }

  /* checked */
  input[type='radio']:checked + label:before {
    color: @base-color;
    color: var(--base-color);
  }

  /* focused */
  input[type='radio']:focus + label:before {
    outline: dotted 1px #aaa;
  }

  /* disabled */
  input[type='radio'][disabled] + label {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.inline {
  display: inline-block;

  &:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.red {
  input[type='radio']:checked + label:before {
    color: #f00 !important;
  }
}

.orange {
  input[type='radio']:checked + label:before {
    color: #f5a623 !important;
  }
}

.green {
  input[type='radio']:checked + label:before {
    color: #68c700 !important;
  }
}
