/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2022 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 * @author Nimesh Sherpa <nimesh.sherpa@bigtincan.com>
 */

@import '../../libs/less/btc-font-mixins.less';
@import '../../libs/less/variables.less';
@import '../../libs/less/tooltips.less';

@iconSize: 20px;

.ViewerToolbar {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 3.125rem;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 1.25rem;
  color: #fff;
  background-color: var(--primary-text);
  white-space: nowrap;
  z-index: 1;

  > div {
    cursor: pointer;
  }

  > .left {
    display: flex;
    align-items: center;

    > button.btnShowFiles {
      border-radius: 0.5rem !important;
      background-color: transparent;
      color: #fff;
      border-color: #fff;
      margin-right: 1rem;
      padding: 0.25rem 0.85rem;

      &:hover {
        background-color: rgba(0,0,0, 0.5) !important;
        color: #eee !important;;
      }
    }
  }
}

@media @mobile, @mobile-xs {
  .ViewerToolbar {
    width: 100%;
    text-align: center;
  }
  .fullscreen {
    display: none !important;
  }
}
@media @tablet {
  .ViewerToolbar {
    text-align: center;
  }
  .fullscreen {
    display: none !important;
  }
}

.right {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.pageIndicator {
  display: inline-block;
  user-select: none;
  margin: 0 0.875rem;
  padding: 0;
  list-style: none;
  vertical-align: middle;

  > li {
    display: inline;
    vertical-align: middle;
  }
  .ofLabel {
    margin: 0 0.5rem;
  }
}

.pageTools,
.zoomTools,
.screenTools,
.rotateTools {
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style: none;
  vertical-align: middle;

  > li {
    display: inline;
    vertical-align: middle;
    padding: 0 0.875rem;
    margin: 0;
    transition: all @timing-fast ease-in;

    &:not(.disabled):hover {
      color: @secondary-text;
    }
  }
}

.currentPage {
  background: transparent;
  color: #fff;
  width: 1.5rem;
  margin: 0 0.15rem;
  text-align: center;
  border: solid 1px #fff;
  border-radius: 3px;
}

.prevPage {
  .icon-prevPage(@iconSize);
  padding-right: 0 !important;
}

.nextPage {
  .icon-nextPage(@iconSize);
  padding-left: 0 !important;
}

.scrollContinuous {
  .icon-scroll-continuous(@iconSize);
}

.scrollSinglePage {
  .icon-scroll-single-page(@iconSize);
}

.zoomIn {
  .icon-zoomIn(@iconSize);
  padding-left: 0 !important;
}

.zoomOut {
  .icon-zoomOut(@iconSize);
  padding-right: 0 !important;
}

.rotate {
  .icon-rotate(@iconSize);
}

.fullscreen {
  .icon-fullscreen(@iconSize);
  &.isActive {
    background-color: #fff;
    color: #000;
    padding: 0.3125rem 0.5rem;
    border-radius: 0.25rem;
  }
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.disappear {
  visibility: hidden;
  opacity: 0;
}

.zoomControls {
  display: block;
  background-color: rgba(76, 76, 76, 0.7);
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  color: #fff;
  z-index: 2;
  position: absolute;
  left: ~'calc(50% + 5px)';
  white-space: nowrap;

  > ul {
    list-style: none;
    text-align: center;
    li {
      &:not(.disabled):hover {
        color: @secondary-text;
      }
    }
  }
}

.zoomLabel {
  //display: inline-block;
  text-align: center;
}

.reverseSort:after {
  transform: rotate(0deg) !important;
}

.sortKey() {
  display: inline-block !important;
  text-align: center;
}

.sortKey {
  .sortKey();
}

.sortKey:after {
  .btc-font();
  font-size: 12px;
  content: '\f185';
  margin-left: 0.3rem;
  margin-bottom: 0.2rem;
  transform: rotate(180deg);
  transition: transform @timing-fast linear;
}

.tableOfContents {
  .icon-toc(1.375rem);
}

.search {
  .icon-search(1.375rem);
}
.thumbnails {
  .icon-doc-pages(1.375rem);
}

.files {
  .icon-file-alt(1.375rem);
}

.topOverlayMenuItem {
  padding: 0.25rem 0.5625rem;
  border-radius: 0.25rem;
  display: flex;

  &.isActive {
    background-color: #fff;
    color: #000;
    &::before {
      color: #000;
      background-color: transparent;
    }
  }
  @media @mobile, @mobile-xs, @tablet {
    margin-right: 0.25rem;
  }
}

.toolTip {
  .tooltip(n);
  .tooltipCustomized(#ffffff, #000000);
  margin-right: 1.25rem;
}

