/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/variables.less';

.UserThumb {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  font-size: 1.1rem;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  transition: all @timing-fast linear;
  margin: 0 auto;
  text-transform: uppercase;
  overflow: hidden;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  // hide default grey border for <img /> placeholder
  &.imageNotLoaded {
    img {
      height: calc(~'100% + 0.5rem');
      width: calc(~'100% + 0.5rem');
      top: auto;
      left: auto;
    }
  }
  -webkit-print-color-adjust: exact;
}
