/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2020 BigTinCan Mobile Pty Ltd
 * @author Olivia Mo <olivia.mo@bigtincan.com>
 */

@import '../../libs/less/btc-font-mixins.less';
@import '../../libs/less/tooltips.less';
@import '../../libs/less/utilities.less';
@import '../../libs/less/variables.less';

.link {
  display: inline-block;
  margin: 0.5rem;
  .tooltip(s);

  &:before {
    bottom: -0.75rem;
    border-bottom-color: #333;
  }

  &:after {
    max-width: 250px;
    .overflow-ellipsis();
    margin-top: 0.75rem;
    background: #333;
  }
}

.thumbnail {
  height: 14.375rem;
  width: 19.4375rem;
  background-color: #f5f5f5;
  position: relative;
  background-size: cover;
  font-size: 1.2rem;
  color: var(--base-text);
  transition: all @timing-fast linear;
  border-radius: 8px;

  &:hover {
    opacity: 0.7;
  }

  span {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.625rem 1rem;
    background-color: rgba(140, 140, 140, 0.8);
    backdrop-filter: blur(0.625rem);
    font-size: 0.875rem;
    text-align: left;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.iconLaunch {
  position: absolute;
  right: 1.0625rem;
  .icon-launch(1.374rem);
}

.noThumb {
  text-align: center;
  .icon-web();
  &:before {
    margin-top: 1rem;
    color: var(--base-text);
    font-size: 7.125rem !important;
    line-height: 1.35 !important;
  }
}
