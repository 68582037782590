/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/variables.less';
@import '../../libs/less/utilities.less';
@import '../../libs/less/btc-font-mixins.less';

.Select {
  &.isDisabled {
    > label {
      cursor: not-allowed;
      color: var(--disabled-color) !important;
    }
  }

  > label {
    cursor: pointer;
    color: var(--secondary-text);
    margin-bottom: 0.25rem;
  }
}

.optionItem {
  &.isChecked {
    .icon-tick-alt();
    position: relative;
    background-color: #fff;
    &:before {
      color: var(--base-color);
      font-size: 0.8125rem;
      position: absolute;
      right: 0.5rem;
      top: 0.75rem;
    }
  }
}

.dropdownIndicator {
  border-style: solid;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
  border-color: var(--base-color) transparent transparent;
  border-width: 0.3125rem 0.3125rem 0;
  &.menuIsOpen {
    border-color: var(--secondary-text) transparent transparent;
  }
}

.placeholder {
  .overflow-ellipsis();
  width: 100%;
  padding-right: 0.5rem;
}
