/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/variables.less';

.Textarea {
  vertical-align: top;

  label {
    cursor: pointer;
    color: var(--secondary-text);
  }

  textarea {
    background: @inputBg;
    border: solid 1px transparent;
    border-radius: 3px;
    color: var(--primary-text);
    display: block;
    margin: 0 0 0.75rem;
    max-width: 100%;
    padding: 0.5rem 0.75rem;
    appearance: none;
    resize: none;

    &:focus {
      outline-color: var(--base-color);
      outline-width: 3px;
    }

    &::-webkit-input-placeholder {
      color: @inputPlaceholderText;
    }
  }
}

.disabled,
.disabled * {
  cursor: not-allowed !important;
}
