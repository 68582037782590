/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../../libs/less/variables.less';

.Image {
  overflow: auto;
  height: 100%;
  width: 100%;
  user-select: none;

  &:focus {
    outline: none;
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    user-select: none;
    image-orientation: from-image; // Firefox only
    object-fit: contain;
  }
}

.loaderWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.lessThanFrame,
.widthGreaterThanFrame {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.heightGreaterThanFrame {
  img {
    transform-origin: top;
    margin-left: auto;
    margin-right: auto;
  }
}

.widthGreaterThanFrame {
  img {
    transform-origin: left;
  }
}

.bothGreaterThanFrame {
  img {
    margin: 0;
    transform-origin: top left;
  }
}

.cursor {
  cursor: -webkit-grab;
}
