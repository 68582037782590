.rw-list {
  .unstyled-list();
  font-size: @list-font-size;
  outline: 0;
  overflow: auto;
  max-height: 200px;
}

.rw-list-option {
  user-select: none;
  color: @list-text-color;
  cursor: pointer;
  border: 1px solid transparent;

  &.rw-state-focus {
    .state-focus(@list-bg-focus, @list-border-focus, @list-color-focus);
  }

  &:hover,
  &:hover.rw-state-focus {
    .state-hover(@list-bg-hover, @list-border-hover, @list-color-hover);
  }

  &.rw-state-selected {
    .state-select(@list-bg-select, @list-border-select, @list-color-select);
  }

  fieldset[disabled] &,
  &.rw-state-disabled,
  &.rw-state-readonly {
    .state-disabled();
    .disabled-color(@list-text-color);

    &:hover {
      background: none;
      border-color: transparent;
    }
  }
}

.rw-list-empty,
.rw-list-option,
.rw-list-optgroup {
  padding: @list-padding-vertical @list-padding-horizontal;
  outline: 0;
}

.rw-list-optgroup {
  font-weight: bold;
  padding-top: 7px;
}

.rw-list-option-create {
  border-top:  1px @widget-border solid;
  //padding-top: 0.357em;
  //margin-top:  0.357em;
}
