/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2023 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/btc-font-mixins.less';
@import '../../libs/less/tooltips.less';
@import '../../libs/less/utilities.less';
@import '../../libs/less/variables.less';

@fileBg: #f9f9f9;
@animation-duration: 2s;

/* Processing Animation */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Striped angle gradient */
.stripedGradient(@startColor: #F26724) {
  background: repeating-linear-gradient(
    -55deg,
    @startColor,
    @startColor 16px,
    lighten(@startColor, 10%) 16px,
    lighten(@startColor, 10%) 33px
  );
}

/* Striped angle gradient with bigtincan orange */
.baseStripedGradient() {
  background: repeating-linear-gradient(
    -55deg,
    @baseColor,
    @baseColor 16px,
    @lightBaseColor 16px,
    @lightBaseColor 33px
  );
}

.FileItem {
  &.isActive {
    background-color: var(--light-base-color);
  }

  // No click event on folders & processing/syncing
  &[data-category='folder'],
  &[data-status='processing'],
  &[data-status='syncing'] {
    cursor: default;
  }

  // No icon on folder (custom svg)
  &[data-category='folder'] {
    > div:before {
      display: none;
    }
  }
}

/* List Styles */
.listItem {
  display: flex;
  padding: 0.5rem;
  align-items: center;
  transition: background @timing-fast linear;
  min-width: 10rem;

  &:hover {
    background-color: @light-base-color;
  }

  &.fileStack {
    // hide stack indicators in list
    &:before,
    &:after {
      display: none;
    }
  }

  &.listItemUnsupportedFile {
    // fix cut-off issue for overflowed tooltips
    position: static;
  }

  .checkbox {
    transition: all @timing-fast linear;
    width: 1.5rem;
    visibility: hidden;

    > div {
      transition: all @timing-fast ease-in-out;
      opacity: 0;
    }
  }

  .processing,
  .syncing {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translate(0, -50%);
    width: 14.5rem;
    max-width: 19%;
    transition: all @timing-fast linear;
  }

  .name {
    flex: 1;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .tags {
      flex: 1;
    }
  }

  .fileMetadataAndControlsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.verticalCenteredInRootContainer {
      position: absolute;
      right: 10vw;
      top: 1.75rem;
    }
    &.noTags {
      width: 100%;
      justify-content: end;
    }
  }

  @media @mobile-xs {
    .category,
    .date,
    .size,
    .tags,
    .shareStatus,
    .shareActive {
      display: none;
    }
  }

  @media @mobile {
    .category,
    .date,
    .size,
    .tags,
    .shareStatus,
    .shareActive {
      display: none;
    }
  }

  @media @tablet {
    .category,
    .size,
    .tags,
    .shareStatus,
    .shareActive {
      display: none;
    }
  }

  @media @desktop {
    .category,
    .size,
    .tags,
    .shareStatus {
      display: none;
    }
  }
}

.processing,
.syncing {
  p {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;

    color: #fff;
    font-size: 0.8rem;
  }
}

/* List Sizes */
.listItemLarge {
}

.listItemMedium {
}

.listItemSmall {
  @media @mobile {
    .category,
    .date,
    .size {
      display: none;
    }
  }

  .name {
    min-width: 10rem;
  }
}

/* Grid Styles */
.gridItem {
  display: inline-block;
  position: relative;
  margin: 1rem;
  cursor: pointer;

  .checkbox {
    z-index: 1;
    margin-left: 0.8rem;
    margin-top: -11rem;
    margin-bottom: 10.356rem;
  }

  .processing,
  .syncing {
    position: absolute;
    top: 5.95rem;
    left: -0.25rem;
    right: -0.25rem;
    height: 1.35rem;
  }

  .name {
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    white-space: pre-wrap;
    cursor: default;
    overflow: hidden !important;
    text-align: center;
  }

  .moreOptionWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .more {
      color: var(--base-color);
      span {
        margin-left: 0;

        &:after {
          .btc-font();
          content: '\f185'; // triangle
          font-size: 10px;
          padding-left: 0.65rem;
        }
      }
    }

    .activeHeading {
      z-index: 60;
      span {
        color: var(--base-color) !important;
        margin-left: 0;
        &:after {
          .btc-font();
          content: '\f185'; // triangle
          font-size: 10px;
          padding-left: 0.65rem;
        }
      }
      ul {
        li {
          padding: 0.55rem 0.75rem;
          font-size: 0.875rem;
          color: var(--primary-text);
        }
      }
    }

    .infoGrid {
      .icon-info(1.1875rem);
    }

    .downloadGrid {
      .icon-download(1.1875rem);
    }

    .editGrid {
      .icon-pen(1.1875rem);
    }
  }
}

/* Grid Sizes */
.gridItemLarge {
}

.gridItemMedium {
  margin: 0.75rem;
  font-size: 0.85rem;

  .processing {
    top: 3.5rem;

    p {
      font-size: 0.75rem;
      line-height: 1.5rem;
    }
  }
}

.gridItemSmall {
  .tooltip(s);
  margin: 0.5rem;
  font-size: 0.8rem;

  &:after {
    max-width: 20rem;
    .overflow-ellipsis();
  }

  .processing {
    top: 2.5rem;

    p {
      font-size: 0.7rem;
      line-height: 1.5rem;
    }
  }

  .name {
    display: none; // shown via tooltip
  }
}

.info {
  width: 1.25rem;

  .infoIcon {
    .icon-info(1.25rem);
    vertical-align: middle;
    cursor: pointer;
    color: var(--base-color);
  }
}

.editFile {
  width: 1.25rem;
  cursor: pointer;
  > span {
    .icon-pen(1.25rem);
    color: var(--base-color);
    vertical-align: middle;
  }
}

.actionContainer {
  width: 6.5rem;
  min-width: 6.5rem;
  display: flex;
  margin-right: -0.5rem;
  justify-content: space-around;
}

/* File Stack */
.fileStack {
  &:before,
  &:after {
    content: '';
    border-top: solid 1px #ddd;
    border-top-color: @divider-color;
    position: absolute;
  }

  &:before {
    top: -4px;
    left: 6px;
    right: 6px;
  }

  &:after {
    top: -2px;
    left: 3px;
    right: 3px;
  }
}

// match width of description in FileList.less
.name {
  white-space: pre;
  color: @primary-text;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    display: flex;
    p {
      width: auto;
      // multiline ellipsis
      display: -webkit-box;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      margin-bottom: 0;

      @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        .line-clamp-ie-fix(1.5625rem, 2, justify, var(--primary-text));
      }
    }
  }
}

.category {
  .overflow-ellipsis();
  color: @secondary-text;
  text-transform: capitalize;
  width: 6.75rem;
  min-width: 6.75rem;

  /* Uppercase abbreviations */
  &[data-category='btc'],
  &[data-category='cad'],
  &[data-category='csv'],
  &[data-category='pdf'],
  &[data-category='rtf'],
  &[data-category='rtfd'],
  &[data-category='txt'],
  &[data-category='xr'],
  &[data-category='3d-model'],
  &[data-category='bnsk'] {
    text-transform: uppercase;
  }
}

.tags {
  width: 100%;
  margin-top: 0.375rem;
  overflow: hidden;

  @media @mobile-xs {
    display: none;
  }

  @media @mobile {
    display: none;
  }

  @media @tablet {
    display: none;
  }

  @media @desktop-xl {
    display: none;
  }

  @media @desktop-xxl {
    display: block;
  }
}

.size {
  .overflow-ellipsis();
  color: @secondary-text;
  width: 6rem;
  min-width: 6rem;
}

.thumbnailNameContainer {
  flex: 1;
  display: flex;
  min-width: 10rem;
}

.date {
  .overflow-ellipsis();
  color: @secondary-text;
  text-align: left;
  width: 10.5rem;
  min-width: 10.5rem;

  @media @tablet {
    text-align: left;
  }
}

.shareStatus {
  .icon-info(0.8rem, ~'after');
  color: @secondary-text;
  text-align: left;
  text-transform: capitalize;

  &:after {
    color: @base-color;
    margin-left: 0.35rem;
  }
}

.shareStatusColumn {
  width: 7rem;
  min-width: 7rem;
  display: inline-block;

  @media @mobile-xs {
    display: none;
  }

  @media @mobile {
    display: none;
  }

  @media @tablet {
    display: none;
  }
}

.shareActive {
  width: 0.5rem;
  height: 0.5rem;
  display: inline-block;
  border-radius: 50%;
}

.shareActive[data-status='mandatory'] {
  background: #f5a623;
}

.shareActive[data-status='blocked'] {
  background: #ff0000;
}

.shareActive[data-status='optional'] {
  background: #68c700;
}

// match .controls in FileList.less
.download {
  color: @base-color;
  text-align: center;
  width: 1.25rem;
  transition: all @timing-fast linear;

  @media @mobile {
    opacity: 1;
  }

  @media @tablet {
    opacity: 1;
    width: 3rem; // @deviceControlWidth in FileList.less
  }
}

.downloadIcon {
  .icon-download(1.25rem);
  cursor: pointer;
  vertical-align: middle;
  transition: all @timing-fast linear;
  opacity: 0;
  &.visible {
    opacity: 1;
  }
}

.tagContainer {
  width: ~'calc(100% + 70px)' !important;
  display: flex;
  position: relative;
  flex: 1;
  overflow: hidden;

  .tagItem {
    height: 30px;
    border-radius: 4px;
    background-color: #eeeeee;
    margin-left: 0.625rem;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
  }

  .tagItem:first-child {
    margin-left: 0 !important;
  }
}

.tagMain {
  display: flex;
  width: 100%;
}

.moreTag {
  .overflow-ellipsis();
  min-width: 60px;
  border-radius: 4px;
  display: flex;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  align-items: center;
  padding: 0 0.6rem;
  color: var(--base-color);
  cursor: pointer;
  font-size: 13px;
}

.moreTagsContainer {
  z-index: 111111;
  position: absolute;
  top: 2.5rem;
  right: 0.75rem;
  background-color: var(--background-color);
  color: var(--secondary-text);
  border: solid 1px #ddd;
  border-color: var(--divider-color);
  border-radius: 3px;
  width: 20rem;
  padding: 0.75rem;
}

.tagPopupContainer {
  display: flex;
  position: relative;
  flex-wrap: wrap;

  .tagItem {
    height: 30px;
    border-radius: 4px;
    background-color: #eeeeee;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    margin-top: 10px;
  }
}

.ThumbnailContainer {
  display: flex;
  align-content: center;
  justify-content: center;
  position: relative;
  .shareActive {
    .tooltip(n);
    position: absolute;
    top: 0.25rem;
    right: 1.5625rem;
    z-index: 10;
  }
}

.description {
  overflow: visible !important;
  justify-content: flex-start;
  p {
    position: relative;
  }
  label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.375rem 0.5rem;
    border-radius: 0.25rem;
    background-color: #eee;
    font-size: 0.875rem;
    color: var(--primary-text);
    margin-right: 0.625rem;
    white-space: no-wrap;
    margin-left: 0.5rem;
  }
  .error {
    .tooltip(n);
    cursor: help;
    z-index: 70;
    right: 0;
    top: 2px;
    color: var(--base-color);
    height: 1.375rem;
    width: 1.375rem;
    margin-left: 0.3125rem;
    // fix cut-off issue for overflowed tooltips
    position: static;

    &[data-longtip]:after {
      top: ~'calc(var(--error-tooltip-top) - 2.55rem)';
      height: 2.8rem;
      left: ~'calc(var(--error-tooltip-left) - 2.25rem)';
      transform: translateX(-50%);
    }
    &[data-longtip]::before {
      top: ~'calc(var(--error-tooltip-top) + 0.25rem)';
      left: ~'calc(var(--error-tooltip-left) - 3.25rem)';
    }

    span {
      .icon-warning(1.25rem);
      color: var(--file-item-error-icon-color);
      font-size: 0.8125rem;
      display: inline-block;
      &::before {
        height: 1.375rem;
        width: 1.375rem;
      }
    }
  }
}

.notag {
  display: flex;
  align-items: center;
  height: 100%;

  p {
    .overflow-ellipsis();
    max-width: ~'calc(100% - 20px)';
    width: max-content;
  }
}

.nameWrapper {
  display: flex;
  justify-content: center;
}

.tooltipCommon(@dir) {
  .tooltip(@dir);

  > span {
    .icon-approved-content(1.35rem);

    &:before {
      color: #f7c94b;
      margin-right: 0.5rem;
      vertical-align: middle;
    }
  }
}

.tooltipListView {
  .tooltipCommon(ne);
}

.tooltipGridView {
  .tooltipCommon(n);
}

.tooltip {
  position: relative;
  cursor: help;
  z-index: 10;
  color: var(--base-color);
  height: 1.25rem;
  margin-left: 1.25rem;
  .tooltip(w);
  .longtip(3rem);
  &[data-longtip]:after {
    width: 19rem;
  }
  > span {
    .icon-info(1.25rem);
    padding-left: 0.1rem;
  }
  &.blocked {
    &[data-longtip]:after {
      width: 11rem;
    }
    > span {
      margin-right: 0.4rem;
    }
  }
  &.unsupported {
    // fix cut-off issue for overflowed tooltips
    position: static !important;
    .tooltip(n);
    &[data-longtip]:after {
      width: 15rem;
      top: ~'calc(var(--unsupported-tooltip-top) - 3.125rem)';
      height: 2.8rem;
      left: ~'calc(var(--unsupported-tooltip-left) - 15rem)';
    }
    &[data-longtip]::before {
      top: var(--unsupported-tooltip-top);
      left: var(--unsupported-tooltip-left);
    }
    > span {
      margin-right: 0.4rem;
    }
  }
}
.disallowDownload,
.disabledDriveFile {
  position: relative;
  cursor: help;
  z-index: 10;
  color: var(--base-color);
  height: 1.25rem;
  margin-left: 1.25rem;
  .tooltip(w);
  .longtip(3rem);
  &[data-longtip]:after {
    width: 18rem;
  }
}
.disallowDownload > span {
  .icon-download-disabled(1.35rem);
  padding-left: 0.1rem;
}
.disabledDriveFile > span {
  .icon-info(1.25rem);
  padding-left: 0.1rem;
}

.removeBtn {
  color: #ff0000;
  line-height: 2.7rem;
  cursor: pointer;
  margin-left: 1.25rem;
  .icon-zoomIn(1rem);
  &:before {
    transform: rotate(45deg);
    font-size: 1rem;
  }
  &:hover {
    opacity: 0.7;
  }
}
.navChevron {
  color: var(--base-color);
  width: 1.375rem;
  display: flex;
  justify-content: center;
  &::before {
    font-size: 1.375rem;
  }
}

.languagesInformation,
.learningObjectDescription {
  color: #777777;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  display: inline-block;
}

.languageVariantFile {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.375rem 0.5rem;
    border-radius: 0.25rem;
    background-color: #eee;
    font-size: 0.875rem;
    color: var(--primary-text);
    margin-right: 0.625rem;
  }

  span {
    display: flex;
    min-height: 2.5rem;
    align-items: center;
    font-size: 0.875rem;
    color: var(--primary-text);
    line-height: 1.57;
    text-transform: capitalize;
  }
}

.fileStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.375rem;
  &.grid {
    position: relative;
    flex-direction: column;
    justify-content: center;
    gap: 0;
    height: 100%;
    right: auto;
    .syncingIcon {
      &::before {
        font-size: 3rem;
        color: #777;
      }
    }
    .processingIcon {
      height: 3rem;
      width: 3rem;
      margin-bottom: 0.25rem;
      .topGear::before {
        font-size: 1.5rem;
        color: #777;
      }
      .bottomGear::before {
        font-size: 1.95rem;
        color: #777;
      }
    }
  }
  p {
    color: #777777;
    font-size: 0.6875rem;
  }
}

.syncingIcon {
  .icon-syncing(1.25rem);
  display: flex;
  margin-top: 2px;
  &::before {
    color: var(--primary-text);
    animation: rotate @animation-duration linear infinite;
  }
}

.processingIcon {
  height: 1.375rem;
  width: 1.375rem;
  position: relative;
  .topGear,
  .bottomGear {
    display: inline-flex;
    position: absolute;
  }
  .topGear {
    .icon-gear(0.625rem);
    top: 3px;
    right: 3px;
    &::before {
      color: var(--primary-text);
      font-weight: bold;
      animation: rotate @animation-duration linear infinite;
      animation-delay: 0.7s;
    }
  }
  .bottomGear {
    .icon-gear(0.8125rem);
    bottom: 0;
    left: 0;
    &::before {
      color: var(--primary-text);
      font-weight: bold;
      animation: rotate @animation-duration linear infinite reverse;
    }
  }
}
