/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/variables.less';

.DateTimePicker {
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
  /** Media Query breakpoints */
  @media @mobile {
  }
  @media @tablet {
  }
  @media @desktop {
  }
  @media @desktop-xl {
  }
}

.dateTimePicker {
  width: 100%;
  & + .tz {
    margin-left: 1rem;
  }
}

.tz {
  min-width: 18rem;
}
