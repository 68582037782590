@import "../../less/_variables.less";
.item_row {
  height: auto;
  display: flex;
  padding-bottom: 10px;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(242, 103, 36, 0.1);
  }

  .checkboxitem {
    margin-left: 20px;
    margin-right: 8px;
    margin-top: 13px;
    .checkbox {
      margin-top: 5px;
    }
  }
  .rightcontainer {
    flex: 1;
    display: flex;
    // border-bottom: 1px solid #c8c7cd;
    .thumb {
      width: 44px !important;
      height: 44px !important;
      margin-top: 13px;
      display: inline-block;
      font-size: 12px;
      text-align: center;
      color: #ffffff;
    }
    .description {
      flex: 1;
      margin-top: 10px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      .title {
        max-height: 38px;
        // font-weight: bold;
        font-size: 13px;
        line-height: 1.46;
        letter-spacing: 0.1px;
        text-align: left;
        color: #222222;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .highlight {
        background-color: var(--light-base-color);
      }
      .excerpt {
        font-size: 11px;
      }
      .subtitlecontainer {
        height: 19px;
        font-size: 10px;
        line-height: 1.9;
        letter-spacing: 0.1px;
        text-align: left;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .subtitle {
          color: #777777;
        }
        .view {
          color: #f07b49;
        }
      }
    }
  }
}

// .active {
//   background-color: rgba(242, 103, 36, 0.1);
// }

.lastitem {
  .rightcontainer {
    border-width: 0px !important;
  }
}

.listCategoryColours(@iterator: 1) when(@iterator <=length(@categories)) {
  @name: extract(extract(@categories, @iterator), 1);
  &[data-category="@{name}"] {
    background-color: extract(extract(@categories, @iterator), 2);
  }
  .listCategoryColours((@iterator + 1));
}
