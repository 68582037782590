/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Rubenson Barrios <rubenson.barrios@bigtincan.com>
 */

@import '../../libs/less/tooltips.less';
@import '../../libs/less/utilities.less';
@import '../../libs/less/variables.less';

.container {
  display: flex;
  justify-content: flex-start;

  > span {
    padding-right: 0.5rem;
    display: inline-block;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    &.name {
      width: 50%;
    }
    &.author {
      width: 30%;
    }
    &.time {
      width: 20%;
    }
    &.time,
    &.author {
      color: var(--secondary-text);
      font-size: 95%;
    }
  }
}

.grid {
  display: block !important;
  > span {
    display: block !important;
    -webkit-line-clamp: initial !important;
    width: 100% !important;
    padding: 0;
    .overflow-ellipsis();

    &.time {
      color: var(--secondary-text);
      font-size: 90%;
    }
  }
}
