/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2020 BigTinCan Mobile Pty Ltd
 * @author Nimesh Sherpa <nimesh.sherpa@bigtincan.com>
 */

@import '../../libs/less/btc-font-mixins.less';
@import '../../libs/less/tooltips.less';
@import '../../libs/less/utilities.less';
@import '../../libs/less/variables.less';

@thumbnail-width: 200px;
@thumbnail-height: 200px;

.fileItem {
  width: @thumbnail-width;
  position: relative;
  display: block;
  cursor: pointer;

  .imageContainer {
    width: @thumbnail-width;
    height: @thumbnail-height;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 0.5rem;
      border: 1px solid @border-color;
      @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        flex: 1; //fix for ie11
      }
    }

    .iconDefault {
      width: 155px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fafafa;
      border-radius: 0.5rem;
      border: 1px solid @border-color;
      .gridCategoryColours();

      &[data-category='dsr'] {
        &::before {
          color: var(--base-color);
        }
      }

      &::before {
        font-size: 3.75rem;
      }
    }
  }

  .fileMetadata {
    text-align: center;
    color: var(--primary-text);
    margin-top: 0.5rem;

    .titleContainer {
      display: flex;
      margin: auto;
      width: 90%;
    }

    span {
      color: @footNote-color;
      font-size: 0.75rem;

      &:nth-child(4) {
        margin: 0 0.25rem;
      }
    }

    .name {
      font-size: 0.875rem;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      width: 100%;

      @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        .line-clamp-ie-fix(1.1875rem, 2, center, var(--primary-text));
      }
    }

    .category {
      font-weight: 600;
      font-size: 0.625rem;
      border-radius: 0.375rem;
      padding: 3px 10px;
      color: #fff;
      color: var(--base-text);
      margin-right: 0.5rem;
    }
  }
}

.portraitStack {
  background-image: url(../FileItemNew/portrait-stack.svg);

  img {
    height: 187px;
    width: 145px;
  }
}

.landscapeStack {
  background-image: url(../FileItemNew/landscape-stack.svg);

  img {
    height: 115px;
    width: 199px;
  }
}

.fileItemList {
  display: flex;

  .descriptionWrapper {
    margin-left: 0.625rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 1.25rem;

    div:nth-child(1) {
      display: flex;
    }

    label {
      color: #222;
      color: var(--primary-text);
      word-break: break-word;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 30rem;
    }

    p {
      margin-bottom: 0;
      font-size: 0.8125rem;
      color: #777;
      color: var(--secondary-text);
    }
  }
}

.fileDate {
  font-size: 0.75rem;
  color: var(--secondary-text);
}

.labelList {
  &:before {
    margin-right: 0.25rem;
  }
}

.tooltip {
  .tooltip(ne);
}

.tooltipToPosition {
  .tooltip(e);
}

.tooltip,
.tooltipToPosition {
  > span {
    .icon-approved-content(1.25rem);

    &:before {
      color: #f26724;
      color: var(--base-color);
      vertical-align: middle;
    }
  }
}
