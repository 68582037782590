/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */
@import '../../libs/less/variables.less';
@import '../../libs/less/utilities.less';

.NotificationItem {
  display: block;
  overflow: hidden;
}

.listItem {
  align-items: center;
  transition: background @timing-fast linear;

  a {
    display: flex;
    align-items: center;
    color: @primary-text;
    padding: 0.5rem;

    &:hover {
      text-decoration: none;
      background-color: @light-base-color;
    }
  }
}

.disabled {
  display: flex;
  align-items: center;
  color: var(--primary-text);
  padding: 0.5rem;
}
.archived {
  color: var(--base-text);
  padding: 0.05rem 0.4rem;
  background-color: var(--secondary-text);
  border-radius: 3px;
}

.gridItem {
  display: inline-block;
  margin: 1rem;
  margin-top: 0;
  max-width: 200px;
}

.userItem {
  > div > a > span {
    color: @secondary-text;
    strong {
      color: @primary-text;
    }
  }
}

.thumbnail() {
  text-align: center;
  background-size: cover;
  background-image: url(../StoryThumb/bg_StoryHeader.svg);
  flex-shrink: 0;

  &:before {
    color: #fff;
  }
}

.listThumbnail {
  .thumbnail();
  margin-right: 0.5rem;

  &:before {
    font-size: 32px;
    line-height: 1.45;
  }
}

.gridThumbnail {
  .thumbnail();

  &:before {
    font-size: 128px;
    line-height: 1.5;
  }
}

.selected {
  background-color: @light-base-color;
}

.name {
  color: @secondary-text;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  strong {
    color: @primary-text;
  }
}
.note {
  color: @secondary-text;
  font-size: 90%;
  display: block;
  text-transform: capitalize;
  .overflow-ellipsis();
}

.lowercaseText {
  text-transform: lowercase;
}
