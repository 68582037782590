/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/variables.less';

@containerWidth: 210px;

@pagesBg: #f8f8f8;
@pagesBorder: #ccc;

@pagesBg-dark: #333;
@pagesBorder-dark: #aaa;
@divider-color-dark: #555;

.ViewerPages {
  width: @containerWidth;
  height: 100%;
  z-index: 1;
  overflow: auto;
  background: @pagesBg;
  border-right: solid 1px #ddd;
  border-right-color: var(--divider-color);
}

// Dark Theme
.isDark {
  background: @pagesBg-dark;
  border-right: solid 1px @divider-color-dark;

  .pageItem {
    > img {
      border: solid 1px @pagesBorder-dark;
      background: @pagesBorder-dark;
    }
  }
}

.listWrap {
  margin: 0;
  padding: 1.25rem 0;
  list-style: none;
}

.pageItem {
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  cursor: pointer;

  height: 170px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &:last-child > p {
    margin-bottom: 0;
  }

  > img {
    display: block;
    max-width: 106px;
    max-height: 130px;
    margin: 0 auto;
    padding: 1px;
    border: solid 1px @pagesBorder;
    border-radius: 2px;
  }
  > p {
    margin: 0.35rem 0 0;
    text-align: center;
    color: @secondary-text;
    font-weight: bold;
  }
}

.activeItem {
  > img {
    padding: 0;
    border-width: 2px;
    border-color: var(--base-color);
  }
  > p {
    color: @base-color;
  }
}
