/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/utilities.less';
@import '../../libs/less/variables.less';

.TabItem {
  display: block;
  overflow: hidden;
  position: relative;

  &.isActive {
    background-color: var(--light-base-color);
  }
}

.noLink {
  cursor: pointer;
}

.showEdit {
  .name {
    position: relative;
    padding: 0 2rem 0 0;
  }
}

/* List Styles */
.listItem {
  align-items: center;
  transition: background @timing-fast linear;

  .info {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1 auto;
  }

  &.noLink {
    padding: 0.5rem;

    &:hover {
      background-color: var(--light-base-color);
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
  }

  a {
    display: block;
    color: var(--primary-text);
    padding: 0.5rem;

    &:hover {
      text-decoration: none;
      background-color: var(--light-base-color);
    }
  }
}

/* Grid Styles */
.gridItem {
  display: inline-block;
  margin: 1rem;
  margin-top: 0;

  .name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.thumbnail() {
  text-align: center;
  background-color: #f5f5f5;
  background-image: url(../StoryThumb/bg_StoryHeader.svg);
  background-position: 50%;
  background-size: cover;
  flex: 0 0 auto;

  &:before {
    color: #fff;
  }
}

.listThumbnail {
  .thumbnail();
  margin-right: 0.5rem;

  &:before {
    font-size: 32px;
    line-height: 1.45;
  }
}

.gridThumbnail {
  .thumbnail();
  margin: 0 auto;

  &:before {
    font-size: 128px;
    line-height: 1.5;
  }
}

.name {
  color: var(--primary-text);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  line-height: 1.25;
  width: calc(~'100% - 1rem');
  display: inline-flex !important;

  .highlighterWrapper {
    // multiline ellipsis
    display: -webkit-box;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      .line-clamp-ie-fix(1.5625rem, 2, justify, var(--primary-text));
    }
  }

  // edit icon if visible
  button {
    position: absolute;
    right: 0;

    &:before {
      font-size: 1rem;
    }
  }
}

.note {
  color: var(--secondary-text);
  font-size: 90%;
  display: block;
  text-transform: capitalize;
  .overflow-ellipsis();
}

.highlight {
  background-color: var(--light-base-color);
}
