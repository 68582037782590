/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import "../../libs/less/variables.less";

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-weight:bold;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

h1 {
  font-size: 2.25rem;
  padding-bottom: 0.3rem;
  line-height: 1.2;
  border-bottom: 1px solid #ddd;
  border-color: var(--divider-color);
}

h2 {
  font-size: 1.5rem;
  margin-top: 0.75rem;
  line-height: 1.225;
  border-bottom: 1px solid #ddd;
  border-color: var(--divider-color);
}

h3 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4;
  color:var(--secondary-text);
}

h4 {
  font-size: 0.9rem;
  font-weight: normal;
  color:var(--secondary-text);
}

h5 {
  font-size: 0.8rem;
  font-weight: normal;
  margin-top:0.5rem;
  color:var(--secondary-text);
}

/* non-text elements */
input, textarea, select {
  .body-font();
}

/* specify font-weight bold */
b, strong {
  .body-font-bold();
}

/* specific italic font */
i, em {
  .body-font-italic();
}

p {
  margin:0 0 0.5rem;

  &:last-child {
    margin-bottom:0;
  }

  &.note {
    font-size:1rem;
    color:#555;
  }
}

/* highlight text */
mark {
  background: #ff0;
  color: #000;
}

blockquote {
  margin:0;
  padding: 0 0.75rem;
  border-left: 4px solid #ddd;
  border-color: var(--divider-color);
  color: var(--secondary-text);

  p {
    margin:0;
  }
}

/* lists */
ol, ul {
  margin:1rem;
  list-style-position: inside;

  li {
    margin:0.45rem 0;
    padding:0;
  }

  ol, ul {
    margin:0.5rem 0 0.5rem 1rem;
  }
}

ol ol, ul ol {
  list-style-type: lower-roman;
}

pre {
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 95%;
  line-height: 140%;
  white-space: pre-wrap;
  margin:0 0 1rem 0;
}

pre > code {
  display: block;
  padding: 0.5rem 1rem;
}

code {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 95%;
  line-height: 140%;
  white-space: pre-wrap;
  background: #f5f5f5;
}
