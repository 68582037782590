/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/tooltips.less';
@import '../../libs/less/utilities.less';
@import '../../libs/less/variables.less';

.StoryItem {
  display: block;
  overflow: hidden;
  position: relative;

  &.isActive {
    background-color: var(--light-base-color);
  }

  > a {
    display: block;
  }
}

.noLink {
  cursor: pointer;
}

/* List Styles */
.listItem {
  align-items: center;
  transition: background @timing-fast linear;

  &.noLink {
    padding: 0.5rem;

    &:hover {
      background-color: var(--light-base-color);
    }
  }

  a {
    display: flex;
    align-items: center;
    color: @primary-text;
    padding: 0.5rem;

    &:hover {
      text-decoration: none;
      background-color: var(--light-base-color);
    }
  }

  .wrapper {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    overflow: hidden;
  }

  .name {
    position: relative;
  }

  .info {
    flex: 1 1 auto;
    overflow: hidden;
  }

  // badgeTitle in list view
  .listBadge {
    position: absolute;
    display: inline-block;
    margin-left: 0.35rem;
    margin-top: 0.05rem;

    > span {
      position: static;
    }
  }

  // subscribe button and comment/ratings in list view
  .buttonsAndCounts {
    flex: 0 1 auto;
    display: flex;
    align-items: center;

    > * {
      padding-left: 0.5rem;
    }
  }

  // override grid StoryBadge styles
  .counts {
    width: auto;
    min-width: 3rem;
    background: none;
    margin-right: 0.5rem;
    color: var(--secondary-text);

    > div {
      position: static;
    }
  }

  .openQuicklinkBtn {
    margin-left: 0.5rem;

    &:hover {
      color: @dark-base-color;
    }
  }
  .quickEditBtn {
    margin-left: 0.5rem;

    &:hover {
      color: @dark-base-color;
    }
  }
}

/* List Sizes */
.listItemLarge {
  .name {
    -webkit-line-clamp: 3;
    max-height: 4.2rem;
  }
  .openQuicklinkBtn {
    &:before {
      font-size: 1rem;
    }
  }
  .quickEditBtn {
    &:before {
      font-size: 1rem;
    }
  }
}

.listItemMedium {
  .name {
    max-height: 2.75rem;
  }

  .openQuicklinkBtn {
    &:before {
      font-size: 1rem;
    }
  }
  .quickEditBtn {
    &:before {
      font-size: 1rem;
    }
  }
}

.listItemSmall {
  .name {
    -webkit-line-clamp: 1;
    max-height: 1.35rem;
  }
}

/* Grid Styles */
.gridItem {
  display: inline-block;
  margin: 0 1rem 1rem 1rem;
  vertical-align: top;
  position: relative;

  &:hover {
    .preview {
      opacity: 1;
    }
  }

  .checkbox {
    position: absolute;
    top: 0.3rem;
    left: 0.5rem;
    z-index: 1;
  }

  @media @mobile {
    margin: 0 0.25rem 0.25rem 0.25rem;
  }
  @media @tablet {
    margin: 0 0.5rem 0.5rem 0.5rem;
  }
  @media @desktop {
    margin: 0 0.75rem 0.75rem 0.75rem;
  }
  @media @desktop-xl {
    margin: 0 1rem 1rem 1rem;
  }
}

/* Grid Sizes */
.gridItemLarge {
  .name {
    -webkit-line-clamp: 1;
    max-height: 1.35rem;
  }
}

.gridItemMedium {
  margin-bottom: 0.75rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;

  .preview {
    padding: 0.5rem;

    .excerpt {
      -webkit-line-clamp: 5;
      max-height: 6.5rem;
    }
    .updated {
      bottom: 0.5rem;
      left: 0.5rem;
    }
    .openQuicklinkBtn {
      bottom: 0.25rem;
      right: 0.25rem;
    }
  }
  .quickEditBtn {
    top: 0.25rem;
    right: 0.25rem;
  }
  .name {
    -webkit-line-clamp: 1;
    max-height: 1.35rem;
  }
}

.gridItemSmall {
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  overflow: visible; // required for tooltip
  .tooltip(n);

  &:after {
    max-width: 20rem;
    .overflow-ellipsis();
  }

  .checkbox {
    top: 0.05rem;
    left: 0.2rem;
  }
}

/**
 * Preview
 */
.preview {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  color: @primary-text;
  padding: 1rem;
  font-size: 90%;
  cursor: pointer;
  transition: opacity @timing-fast ease-in;
  opacity: 0; // shown on hover

  .excerpt {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 8rem;
    word-wrap: break-word;
  }

  .updated {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
  }

  .openQuicklinkBtn {
    position: absolute;
    bottom: 0.75rem;
    right: 0.75rem;

    &:hover {
      background-color: @divider-color;
    }
    &:active {
      color: @dark-base-color;
    }
  }
}

.openQuicklinkBtn {
  background: transparent;
  transition: all @timing-fast linear;
}
.quickEditBtn {
  background: transparent;
  transition: all @timing-fast linear;
  position: absolute;
  color: #ffffff;
  top: 0.75rem;
  right: 0.75rem;
  width: 18px;
  height: 18px;
}

/**
 * Story info
 */
.name {
  color: @primary-text;
  display: inline-block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.note {
  color: @secondary-text;
  font-size: 90%;
  display: block;
  .overflow-ellipsis();
}

// Unsubscribe confirmation
.confirm {
  color: #f00;
  cursor: pointer;
  user-select: none;
}

.confirmUnsubscribe {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  background-color: var(--light-base-color);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      cursor: pointer;
      display: inline-block;
      margin: 0 1rem;
      padding: 0 0.5rem;
    }
  }
}
