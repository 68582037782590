@import "../../../assets/style-guide/src/libs/less/btc-font-mixins.less";
@import "../../../assets/style-guide/src/libs/less/variables.less";
@import "../../less/_variables.less";

.header {
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--divider-color);
  align-items: center;
  padding: 0px 20px;
  .title {
    width: 95px;
    height: 18px;
    font-size: 13px;
    text-align: left;
    color: #222222;
  }
  .setting {
    &:before {
      content: "\F138";
      color: @secondary-text;
      font-family: "btc-font";
      font-size: 15px;
      margin-right: 10px;
    }
  }
}
.tooltipIcon {
  margin-left: 0.375rem;
  margin-top: 0.125rem;
  color: var(--base-color);
}

.selected_files_block {
  height: auto;
  background-color: #ffffff;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #dddddd;
  padding: 10px 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // font-family: "btc-font";
  align-items: flex-start;
  // font-size: 13px;
  text-align: left;
  // color: #222222;
}

.home {
  width: 100%;
  height: auto;
  background-color: white;
  overflow:scroll;
  .container {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }
}

.home_file_block {
  background-color: #ffffff;
  height: auto;
  display: flex;
  flex-direction: column;
  font-size: small;
  .checkboxes {
    margin-bottom: 0.5rem;
    margin-left: 0.25rem;
    label {
      color: #000;
    }
  }
  .infoSpan {
    color: #777;
    margin-bottom: 0.75rem;
    padding-left: 1.5625rem;
    display: block;
  }
  .selectFormContainer {
    align-items: center;
    margin-top: 0.625rem;
    padding-left: 1.5625rem;

    > span {
      text-transform: capitalize;
    }
    margin-bottom: 0.75rem;
  }
}
.expireDaysContainer {
  align-items: center;
  margin-bottom: 0.5rem;
  padding-left: 1.5625rem;

  > span {
    text-transform: capitalize;
  }
}

.attachedFilesHeader {
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid;
  border-color: var(--divider-color);
  padding-top: 1rem;
  .attachedFilesTitle {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    h3 {
      margin: 0;
    }
    .filesCounter {
      color: #fff;
      background-color: var(--base-color);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.375rem;
      border-radius: 0.6875rem;
      padding: 0 0.6875rem;
      margin-left: 0.5rem;
      font-size: 0.8125rem;
      font-weight: 600;
    }
  }

  .addFilesBtn,
  .removeAllBtn {
    margin: 5px;
    padding: 5px 15px !important;
    font-size: 0.8125rem !important;
  }
  .removeAllBtn {
    background-color: #777 !important;
    color: #ddd !important;
    border: none !important;
  }
}

.noFilesMessage {
  padding: 0 1rem;
  color: #777;
  font-size: 0.875rem;
  margin: 1.375rem 0 0 0;
  padding-bottom: 2.5625rem;
}

.bodyWrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.25rem 0rem 1rem;

  h4 {
    font-weight: bold !important;
  }

  p.descriptionMessage {
    color: #777;
    font-size: 0.815rem;
    margin-bottom: 1.25rem;
  }

  .checkboxes {
    margin-bottom: 0.625rem;
    margin-left: 0.25rem;

    label {
      color: #000;
    }
  }
  .infoSpan {
    color: #777;
    margin-bottom: 0.75rem;
    display: block;
  }
}

.formContainer {
  padding: 0 1rem;
  .title {
    font-size: small;
  }
}

.shareLinkModal {
  h3 {
    font-size: 1.125rem;
    color: var(--primary-text);
    height: 4rem;
    padding: 0 1.25rem;
    margin: 0;
    display: flex;
    align-items: center;
  }
  .row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1.875rem 1.25rem 2.5rem 1.25rem;
  }
  .shareableLink {
    width: 19.1875rem;
    height: 2.625rem;
    > span {
      margin-bottom: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 17.1875rem;
      height: 100%;
      background-color: #eee;
    }
  }
  .copyToClipboardBtn {
    height: 2.625rem;
  }
  .openInShareConsoleBtn {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
}

.fileItem {
  font-size: small;
  padding: 0.5rem 10px;
  flex: 1;
  &:hover {
    background-color: #fff !important;
  }
  > div:first-child {
    max-width: inherit !important;
  }
  > div:nth-child(2) {
    max-width: inherit !important;
  }
  > div:nth-child(1) {
    max-width: inherit !important;
  }
  > div:last-child {
    margin: 0;
    padding-right: 0.5rem;
  }
}

.fileListContainer {
  overflow: auto;
}

.footer {
  height: 49px;
  background-color: #ffffff;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #dddddd;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  .title {
    height: 18px;
    font-size: 13px;
    text-align: left;
    color: #222222;
    margin-top: 16px;
    margin-left: 20px;
  }
  .action {
    .attchbtn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // font-size: small;
      // margin: 5px;
      // padding: 5px 15px !important;
      font-size: 0.8125rem !important;
    }
    .removeAllBtn {
      padding: 5px 15px;
      font-size: 0.8125rem;
      background-color: #777;
      color: #ddd;
      border: none;
    }
  }
}

.showInfo {
  background-color: #4bca81;
  .title {
    text-align: center !important;
    width: 100%;
    font-size: 13px;
    color: #ffffff;
  }
  .oppCmdContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.showError {
  background-color: var(--error-color);
  .title {
    text-align: center !important;
    width: 100%;
    font-size: 13px;
    color: #ffffff;
  }
  .oppCmdContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.fileList {
  margin: 0;
  min-height: 80px;
  max-height: 160px;

  > div.fileItemWrapper {
    position: relative;
    padding: 0 1rem 0 0;
    display: flex;
  }
  .removeBtn {
    position: absolute;
    right: 1.125rem;
    line-height: 50px;
    padding: 0.5rem 0;
    margin-top: 0.3rem;
  }
}

.mandatory {
  color: red;
  margin-left: 2px;
}

.attach_file_block {
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #dddddd;
  padding: 0px 20px;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  .title {
    height: 18px;
    font-size: 13px;
    text-align: left;
    padding-left: 14px;
    color: #222222;
  }
  .icon {
    height: 22px;
    object-fit: contain;
    &:before {
      content: "";
      width: 21px;
      height: 22px;
      object-fit: contain;
      background-image: url(../../../static/img/attach-icon@2x.png);
      background-size: 100%;
      background-repeat: no-repeat;
      display: block;
    }
  }
  .disclosure_indicator {
    margin-left: auto;
    &:before {
      content: "\F153";
      color: @secondary-text;
      font-family: "btc-font";
      font-size: 15px;
      margin-right: 10px;
    }
  }
}
