/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2020 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/btc-font-mixins.less';
@import '../../libs/less/variables.less';

.DropMenu {
  position: relative;
  user-select: none;
}

.DropMenuActive {
  color: #777;
  color: var(--secondary-text);

  // .headingWrap
  > div {
    color: #777;
    color: var(--secondary-text);

    &:before {
      color: #f26724;
      color: var(--base-color);
    }
  }

  // .contentWrap
  .contentWrap {
    display: block;
    z-index: 10;
  }

  .icon {
    color: #b03100;
    color: var(--dark-base-color);
  }
}

.DropMenuDisabled {
  cursor: pointer;
}

// Button style
.buttonMenu {
  background-color: #f26724;
  background-color: var(--base-color);
  color: #fff;
  color: var(--base-text);
  border: solid 1px;
  border-color: #f26724;
  border-color: var(--base-color);
  padding: 0.15rem 0.5rem;
  border-radius: 3px;
  display: inline-block;

  .heading {
    padding-right: 0.25rem;
    transition: color @timing-fast ease-out;

    &:after {
      .btc-font();
      color: #fff;
      content: '\f185'; // triangle
      font-size: 10px;
      padding-left: 0.65rem;
    }
  }

  .contentWrap {
    top: 2.2rem;
  }
}

.invertedButtonMenu {
  background-color: #fff;
  color: #f26724;
  color: var(--base-color);
  border: solid 1px;
  border-color: #f26724;
  border-color: var(--base-color);

  .heading {
    &:after {
      color: #f26724;
      color: var(--base-color);
    }
  }
}

.buttonMenuActive {
  .heading {
    color: #fbd4bf;
    color: var(--light-base-color);

    &:after {
      color: #fbd4bf;
      color: var(--light-base-color);
    }
  }
}

.headingWrap {
  display: inline-block;

  &:before {
    padding-right: 0.5rem;
    vertical-align: middle;
  }
}

.icon {
  color: #f26724;
  color: var(--base-color);

  &:before {
    font-size: 1.25rem;
    vertical-align: middle;
  }
}

.contentWrap {
  display: none;
  position: absolute;
  min-width: 9rem;
  top: 1.75rem;
  right: 0;
  margin: 0;
  padding: 0;
  background-color: #fff;
  background-color: var(--background-color);
  color: #777;
  color: var(--secondary-text);
  border: solid 1px;
  border-color: #ddd;
  border-color: var(--divider-color);
  border-radius: 4px;

  ul {
    list-style: none;
  }

  > ul {
    margin: 0;
    padding: 0;
  }

  > ul > li {
    margin: 0;
    list-style: none;
    padding: 0.55rem 0.75rem;
    font-size: 90%;

    /** icon */
    &:before {
      width: 2rem;
      text-align: center;
      padding-right: 0.5rem;
      color: #f26724;
      color: var(--base-color);
      vertical-align: middle;
    }

    &:hover {
      background-color: #fbd4bf;
      background-color: var(--light-base-color);
    }

    span {
      vertical-align: middle;
    }
  }
}

.contentWrapActive {
  top: 2.2rem;
  display: block;
  z-index: 10;
}

.heading {
  margin-left: 0.5rem;

  &:after {
    vertical-align: middle;
  }
}
