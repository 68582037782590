/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/variables.less';

.Plaintext {
  margin: 0 auto;
  height: 100%;
  overflow: auto;

  &:focus {
    outline: none;
  }

  pre {
    .body-font();
    white-space: pre-line;
    height: 100%;
    margin-bottom: 0;
    padding: 1.25rem 1.25rem 0;
    @media @mobile, @mobile-xs, @tablet {
      margin-top: 3.125rem;
    }
  }
}
