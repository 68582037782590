@import "../../../assets/style-guide/src/libs/less/btc-font-mixins.less";
@import "../../../assets/style-guide/src/libs/less/variables.less";
.log_email_to_salesfo {
  height: auto;
  background-color: #ffffff;
  align-items: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--divider-color);
  padding: 0rem 0.75rem 0.75rem 0.75rem;
  p {
    color: #777;
    font-size: 0.815rem;
    margin-bottom: 1.25rem;
  }
  .title {
    height: 18px;
    font-size: 13px;
    text-align: left;
    padding-left: 10px;
    color: #222222;
  }
  .icon {
    height: 22px;
    object-fit: contain;
    display: inline-block;
    padding-left: 10px;
    &:before {
      content: "";
      margin-top: 10px;
      position: sticky;
      width: 25px;
      height: 19px;
      object-fit: contain;
      background-image: url(../../../static/img/sf-logo@2x.png);
      background-size: 100%;
      background-repeat: no-repeat;
      display: block;
    }
  }
  .disclosure_indicator {
    margin-left: auto;
    display: inline-block;
    label {
      font-size: small;
      color: #222222;
    }
  }
}

.footer {
  padding: 12px 20px;
  width: 100%;
  height: 50px;
  background-color: #ffffff;
  .title {
    height: 18px;
    font-size: 13px;
    text-align: left;
    padding-left: 10px;
    color: rgb(34, 34, 34);
  }
}

.showInfo {
  background-color: #4bca81;
  .title {
    text-align: center !important;
    width: 100%;
    font-size: 13px;
    color: #ffffff;
  }
}

.crmBlock {
  flex: 1;
  height: ~"calc(100% - 120px) !important";
  overflow: scroll;
}

.crmBlockRoot {
  height: ~"calc(100% - 120px) ";
  overflow: hidden;
}

.oppCmdContainer {
  display: flex;
  justify-content: space-between;
}

.oppCmdContainer_info {
  display: flex;
  background-color: #4bca81;
  justify-content: space-between;
}

.btn {
  font-size: 14px;
  width: 99px;
  height: 30px;
  border-radius: 3px;
  background-color: #f07b49;
}

.crmErrorBlock {
  height: 80px;
  .title {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.46;
    letter-spacing: 0.1px;
    color: #222222;
  }
}
