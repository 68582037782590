
@import "../../../assets/style-guide/src/libs/less/btc-font-mixins.less";
@import "../../../assets/style-guide/src/libs/less/variables.less";
.conainer{
    width: ~"calc(100% - 20px)";
    margin: 0 auto;
    height: ~"calc(100% - 20px)";
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    
}
.cmdcontainer{
    display: flex; 
    justify-content: flex-end;
}
.signincontainer{
    display: flex; 
    justify-content: center;
    position: absolute;
    bottom: 25px;
    width: 100%;
}
.skipbtn{
    font-size: 15px;
    cursor:pointer;
    margin-right: -15px !important;
}
.skipbtn:after {
    content: '\F153';
    font-family: "btc-font";
    margin-left: 5px;
    font-size: 8px;
    vertical-align: middle;
}
.signbtn{
    font-size: 15px;
    width: 280px;
    height: 40px !important;
    cursor:pointer;
    border-radius: 3px;
    background-color: #f07b49;
   
	font-size: 16px;
	text-align: center;
    color: #ffffff;
    
}
.introfirstimage{
    background-image:url(../../../static/img/log-illustration@2x.png);
    background-size:100%;
    background-repeat:no-repeat;
}
.introsecondimage{
    background-image:url(../../../static/img/entity-illustration@2x.png);
    background-size:100%;
    background-repeat:no-repeat;
    background-size: cover;
}
.introfthirdimage{
    background-image:url(../../../static/img/attach-illustration@2x.png);
    background-size:100%;
    background-repeat:no-repeat;
}



@dots-position: -150px;
:global{
    .slick-slider{
        height: ~"calc(100% - 150px)";
    }
    .slick-prev:before {
        color: @secondary-text;
        content: '\F165';
        color: @secondary-text;
        font-family: "btc-font";
    }
    .slick-next:before {
        content: '\F153';
        color: @secondary-text;
        font-family: "btc-font";
    }
    .slick-dots li.slick-active button:before {
        opacity: .75;
        color:@baseColor;
    }
    .slick-list, .slick-track,.slick-slide{
        height: 100%;
    }
    .slick-dots{
        position: absolute;
        bottom: @dots-position;
        li {
            width: 7px;
            height: 7px;    
        }
    }
    .slick-dots li button:before {
        font-size: 6px;
        /* line-height: 20px; */
        position: absolute;
        top: 0;
        left: 0;
        /* width: 20px; */
        /* height: 20px; */
        content: '•';
        text-align: center;
        opacity: .25;
        color: #000;
    }
    .slick-disabled{
        display: none !important;
    }
    .centerimage{
        height: 114px !important;
    }
}