/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Rubenson Barrios <rubenson.barrios@bigtincan.com>
 */

@import '../../libs/less/btc-font-mixins.less';
@import '../../libs/less/variables.less';
@import '../../libs/less/utilities.less';

.InterestAreaItem {
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 175px;
  width: 130px;
}

.listItem {
  padding: 0.5rem;
  position: relative;

  &:hover {
    background-color: @light-base-color;
  }
}

.gridItem {
  display: inline-block;
  text-align: center;
  margin: 1rem;
  max-width: 200px;
  vertical-align: top;
  border: 2px solid #eee;
  border-radius: 4px;
  background-color: #eee;
  transition: all 0.2s ease;
  padding-bottom: 1px;

  .name {
    line-height: 43px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 124px;
    display: block;

    &:hover {
      color: @base-color;
    }
  }
  .gridThumbnail:hover {
    opacity: 0.9;
  }

  &.selected {
    border-color: var(--base-color);
    .name {
      color: #fff;
    }
  }
}

.thumbnail,
.gridThumbnail {
  position: relative;
  display: block;
  background-size: cover;
  background-position: center;
  font-size: 1.2rem;
  width: 130px;
  height: 130px;
  text-align: center;
  color: @primary-text;
  transition: all @timing-fast linear;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.thumbnail {
  margin-right: 0.5rem;
}
.gridThumbnail {
  margin: 0 auto 0;
  cursor: pointer;
}

.selected {
  background-color: var(--base-color);
}

.name {
  color: @primary-text;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
}
