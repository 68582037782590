.selectedItem {
  background-color: rgba(242, 103, 36, 0.1);
}

.itemblock {
  display: flex;
  padding: 0px 20px;
  padding-top: 10px;
  border-bottom: 1px solid #c8c7cd;
  .close {
    margin-top: 5px;
    padding: 4px;
    width: 30px;
    padding-left: 10px;
    cursor: pointer;
    &:before {
      content: "\f110";
      color: #777777;
      font-family: "btc-font";
      font-size: 13px;
    }
  }
  .body {
    margin-left: 5px;
    margin-right: auto;
    display: inline-block;
    height: 60px;
    flex: 1;
    .title {
      display: inline-block;
      flex-wrap: wrap;
      flex-direction: column;
      height: 19px;
      font-size: 13px;
      font-weight: 600;
      line-height: 1.46;
      letter-spacing: 0.1px;
      text-align: left;
      color: #222222;
      white-space: normal;
      overflow: visible;
      text-overflow: ellipsis;
      width: 100%;
    }
    .accountBlock {
      font-size: 12px;
      line-height: 1.58;
      letter-spacing: 0.1px;
      text-align: left;
      color: #777777;
      span {
        font-weight: 600;
      }
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 92%;
    }
  }
  .control {
    .regionradio {
      margin-top: 8px;
      margin-right: 10px;
    }
  }
}

:global {
  .firstopp {
    padding-top: 5px !important;
  }
}

.lastitem {
  .body {
    border-bottom: 0px solid #c8c7cd !important;
  }
}
