/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@barWidth: 4px;
@ballWidth: 12px;

.VolumeControl {
  position: absolute;
  bottom: 22px;
  left: -2px;
  right: 0;
  padding: 1rem 0;
  cursor: pointer;
}

.isDragging {
  cursor: grabbing !important;
}

.volumeSlider {
  position: relative;
  margin: 0 auto;
  height: 100px;
  width: @barWidth;
  border-radius: 5px;
}

.volumeBar {
  position: absolute;
  bottom: 0;
  top: 70%;
  width: @barWidth;
  display: block;
  border-radius: 5px;
}

.volumeBall {
  position: absolute;
  left: -@barWidth;
  width: @ballWidth;
  height: @ballWidth;
  transform: translate(0, -@ballWidth / 2);
  border-radius: 50%;
  cursor: grab;
}
