/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2020 BigTinCan Mobile Pty Ltd
 * @author Olivia Mo <olivia.mo@bigtincan.com>
 */

@import '../../libs/less/variables.less';

.shareItemContentWrapper {
  display: flex;
  align-items: center;
  overflow: hidden;

  .shareItemContent {
    white-space: nowrap;
    overflow: hidden;

    .name {
      -webkit-box-orient: vertical;
      white-space: nowrap;
      -webkit-line-clamp: 1;
      max-height: 1.35rem;
      color: #777;
      color: var(--primary-text);
      display: inline-block;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .wrappedName {
      -webkit-box-orient: vertical;
      white-space: normal;
      -webkit-line-clamp: 1;
      max-height: 1.35rem;
      color: #777;
      color: var(--primary-text);
      display: inline-block;
    }

    .wrappedName,
    .name {
      &:hover {
        text-decoration: underline;
      }
    }

    div {
      &.wrapText {
        white-space: normal;
      }

      span,
      a {
        color: #222;
        color: var(--secondary-text);
        font-size: 0.8125rem;
        padding: 0;
        display: inline-block;
      }

      a:hover {
        text-decoration: underline;
      }

      span {
        padding: 0 0.25rem;
      }
    }
  }
}
