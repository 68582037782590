/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Rubenson Barrios <rubenson.barrios@bigtincan.com>
 */

@import '../../libs/less/btc-font-mixins.less';
@import '../../libs/less/tooltips.less';
@import '../../libs/less/utilities.less';
@import '../../libs/less/variables.less';

.GroupItem {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;

  &.isActive {
    background-color: var(--light-base-color);
  }

  &.showAdmin {
    display: block !important;
    overflow: initial !important;
    .name {
      padding: 0 !important;
    }
  }
}

.noLink {
  cursor: pointer;
}

.showEdit {
  .name {
    position: relative;
    padding: 0 2rem 0 0;
  }
}

.showDelete {
  .name {
    position: relative;
    padding: 0 2rem 0 0;
  }
}

/* List Styles */
.listItem {
  padding: 0.5rem;
  position: relative;

  &:hover {
    background-color: @light-base-color;
  }

  .wrapper {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .info {
    margin-left: 0.5rem;
  }

  &.noLink {
    padding: 0.5rem;

    &:hover {
      background-color: @light-base-color;
    }
  }
}

/* List Sizes */
.listItemLarge {
  .name {
    -webkit-line-clamp: 3;
    max-height: 4.1rem;
  }
}

.listItemMedium {
  .name {
    max-height: 2.65rem;
  }
}

.listItemSmall {
  .name {
    -webkit-line-clamp: 1;
    max-height: 1.35rem;
  }
}

/* Grid Styles */
.gridItem {
  display: inline-block;
  text-align: center;
  margin: 1rem;
  max-width: 200px;
  vertical-align: top;

  .name:hover {
    color: @base-color;
  }
}

/* Grid Sizes */
.gridItemLarge {
  width: 8rem;
}

.gridItemMedium {
  width: 6.5rem;
}

.gridItemSmall {
  margin: 0.5rem;
  overflow: visible; // required for tooltip
  .tooltip(n);

  &:after {
    max-width: 20rem;
    .overflow-ellipsis();
  }
}

.selected {
  background-color: @light-base-color;
}

.info {
  flex: 1 1 auto;
}

.name {
  color: @primary-text;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.note {
  color: @secondary-text;
  display: block;
  font-size: 90%;
  .overflow-ellipsis();
}

.education {
  &:before {
    content: '\f1e1' !important;
  }
  .icon-education(4rem);
  background-color: var(--divider-color);
}
