/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/variables.less';

.fullFixed() {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Csv {
  height: calc(~'100%' - 3.125rem);
  width: 100%;
  overflow: auto;

  &:focus {
    outline: none;
  }

  @media @mobile, @mobile-xs, @tablet {
    height: calc(~'100%' - 6.25rem);
    margin-top: 3.125rem;
  }
}

.fillParent {
  .fullFixed();
}

.loading {
  .fullFixed();

  display: flex;
  justify-content: center;
  align-items: center;
}

.tableContainer {
  padding: 1rem;
  background-color: #000;
}

.csvTable {
  width: 100%;
  margin: 0 auto;
  background-color: #fff;

  td,
  th {
    white-space: pre;
  }

  &:focus {
    outline: none;
  }
}

.rowNumber {
  text-align: center;
  min-width: 2rem;
}

.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
