/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Jason Huang <jason.huang@bigtincan.com>
 */

@import '../../../libs/less/variables.less';

.fullFixed() {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.PresentationViewer {
  .fullFixed();
  display: flex;

  background-color: black;

  &:focus {
    outline: none;
  }

  > div {
    overflow: hidden;
    @media @mobile, @mobile-xs, @tablet {
      overflow: visible;
    }
  }

  iframe {
    height: calc(100% - 3.1625rem);
    width: 100%;
    display: block;
    &:focus {
      outline: none;
    }
  }
}

.loading {
  > div {
    height: auto;
  }
}

.Frame {
  width: 100%;
  height: 100%;
}

.paddingLeft {
  padding-left: 1rem;
}

.paddingRight {
  padding-right: 1rem;
}

.disablePointEvents {
  pointer-events: none;
}

div.viewerHeader-docPages {
  padding: 0px;

  > div {
    margin-top: 0px;
    height: 100%;
    background-color: #f8f8f8;
    z-index: 5;

    li {
      > div {
        color: @primaryText;

        > div {
          display: none;
        }
      }
    }
  }
}
