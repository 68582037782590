/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Jason Huang <jason.huang@bigtincan.com>
 */

@import '../../libs/less/btc-font-mixins.less';
@import '../../libs/less/variables.less';
@import '../../libs/less/utilities.less';

@noteThumbnailWidth: 100;
@listNoteThumbnailWidth: 55;
@storyThumbnailWidth: 25;
@listStoryThumbnailWidth: 15;

.ellipsis() {
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
}

.background(@width, @height) {
  width: @width*1px;
  height: @height*1px;
  min-width: @width*1px;
  min-height: @height*1px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.gridItem {
  position: relative;
  display: inline-block;
  margin: 0 0rem 1.25rem 1.25rem;
  padding: 1rem;
  vertical-align: top;
  overflow: hidden;

  width: 187px;
  height: 242px;
  border-radius: 3px;
  border: solid 1px @dividerColor;
  border-color: var(--divider-color);

  &:hover {
    &:extend(.selectedGrid);
  }
}

.selectedGrid {
  border-color: var(--base-color);
}

.selectedList {
  background-color: var(--light-base-color);
}

.secondGrid {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.listItem {
  padding: 0.5rem;
  display: flex;
  &:hover {
    &:extend(.selectedList);
  }
}

.loader {
  display: flex;
  justify-content: center;
}

.loaderItem {
  align-self: center;
}

.noteThumbnail {
  .background(@noteThumbnailWidth, @noteThumbnailWidth*0.665);
}

.listNoteThumbnail {
  .background(@listNoteThumbnailWidth, @listNoteThumbnailWidth);
}

.storyThumbnail {
  .background(@storyThumbnailWidth, @storyThumbnailWidth);
}

.listStoryThumbnail {
  .background(@listStoryThumbnailWidth, @listStoryThumbnailWidth);
}

.iconTxtAlignLeft {
  .icon-note(55px);
}

.paddingLeft {
  padding-left: 0.5rem;
}

.defaultBG {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: var(--base-text);

  &:before {
    color: var(--divider-color);
  }
}

.defaultStoryBG {
  background-image: url(../StoryThumb/bg_StoryHeader.svg);
}

.storyLink {
  display: flex;
  align-items: center;
  flex: 1;

  span {
    font-size: 13px;
    color: var(--secondary-text);
    opacity: 0.6;
    padding-left: 5px;
    -webkit-line-clamp: 1;
    .ellipsis();
  }
}

.listItemTitle {
  display: flex;
  justify-content: space-between;
}

.title {
  color: var(--primary-text);
  line-height: 1.4;
  margin-bottom: 0.25rem;
  .ellipsis();
}

.visibility {
  visibility: hidden;
}

.content {
  font-size: 90%;
  color: var(--secondary-text);
  line-height: 1.4;
  //flex: 1;
  .ellipsis();
}

.flexboxColumn {
  display: flex;
  justify-content: center;
}

.footer {
  font-size: 0.8rem;
  color: var(--base-color);
  margin-top: 0.25rem;
}

.checkbox {
  position: absolute;
  top: 0.3rem;
  right: 0;
  z-index: 1;
}

.hidden {
  display: none;
}

.white {
  color: @base-text !important;
  background: none;
  line-height: 1.8;
  margin-bottom: 0rem;
}

.smallIcon {
  .icon-note-fill(24px);
  width: auto;
  height: auto;
  min-width: auto;
  min-height: auto;
}
