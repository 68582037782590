/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../../libs/less/variables.less';

@containerWidth: 132px;

@pagesBg: #f8f8f8;
@pagesBorder: #ccc;

@pagesBg-dark: @primary-text;

@iconColor: #ddd;

@inputBg: #f5f5f5;

.PdfViewer {
  height: 100%;

  > div {
    height: 100%;
    position: relative;
    display: flex;
  }
}

.PasswordBlock {
  height: 100%;
  position: relative;
  text-align: center;
  justify-content: center;
  margin-top: 100px;
  display: block !important;

  p.error {
    color: #f00;
    text-align: center;
  }
}

.PasswordProtected {
  width: 223px;
  font-size: 20px;
  text-align: center;
  color: #222222;
}

.PasswordMessage {
  color: #777;
  margin-bottom: 35px;
}

.input {
  .body-font();
  color: @secondary-text;
  background: @inputBg;
  border: solid 1px transparent;
  border-radius: 3px;
  width: 350px;
  text-align: center;
  margin: 0 1.5rem 0.75rem;
  padding: 0.5rem 0.75rem;
  transition: background @timing-fast ease-in;

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  & + button {
    margin-top: 3.5rem;
  }
}

.LockIcon {
  color: @iconColor;
  display: block;
}

.pdfviewerSection {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
