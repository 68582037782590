/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/variables.less';

@size: 46px;

.List {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
  overflow-x: hidden;
  height: 100%; // causes problems in Safari in some cases

  > li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.subList {
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.grid {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  align-content: flex-start;
  flex-flow: row wrap;
  height: 100%;
  overflow: auto;

  > li {
    margin: 0;
    padding: 0;
    list-style: none;
    flex-shrink: 0;
  }
}

.inline {
  white-space: nowrap;
  display: block;
  overflow-x: auto;
  padding-bottom: 0.5rem;

  > li {
    vertical-align: top;
    display: inline-block;
  }
}

.listTitle {
  margin-left: 0.5rem;
}

.listLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.loadingMore {
  flex-basis: 100%;
  -webkit-flex-basis: 100%; // autoprefix not adding for Safari 8?
  margin: 1rem auto 2rem !important;

  /* loading indicator */
  > div {
    margin: 0 auto;
  }
}
.titleContainer {
  display: flex;
  justify-content: space-between;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    margin-right: 0.5rem;
    padding-top: 0.15rem;
    li {
      padding: 0 0.9rem;
      color: @primary-text;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      outline: 0;
    }
    .actionItem {
      color: @base-color;
      font-size: 0.7rem;
      vertical-align: bottom;
    }
  }
}
