/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

//@import "../../libs/less/variables.less";

@badgeSpacing: 8px;

.thumbnail() {
  text-align: center;
  background-color: #f5f5f5;
  background-image: url(../StoryThumb/bg_StoryHeader.svg);
  background-position: 50%;
  flex: 0 0 auto;

  &:before {
    color: #fff;
  }
}

.StoryThumb {
  .thumbnail();
}

.listThumbnail {
  margin-right: 0.5rem;

  &:before {
    font-size: 32px;
    line-height: 1.45;
  }
}

.gridThumbnail {
  &:before {
    font-size: 128px;
    line-height: 1.5;
  }
}
