/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2020 BigTinCan Mobile Pty Ltd
 * @author Olivia Mo <olivia.mo@bigtincan.com>
 */

@import '../../libs/less/variables.less';

.StoryThumb {
  text-align: center;
  background-color: @inputBg;
  background-image: url(../StoryThumbNew/bg_StoryHeader.svg);
  background-position: 50%;
  flex: 0 0 auto;
  border-radius: 0.5rem;

  &:before {
    color: #fff;
    color: var(--base-text);
  }
}

.gridThumbnail {
  &:before {
    font-size: 128px;
    line-height: 1.5;
  }
}

.listThumbnail {
  margin-right: 0.625rem;
}

.cardThumbnail {
  border-radius: 0;
  border: none;
  margin-right: 0.625rem;
}
