/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Rubenson Barrios <rubenson.barrios@bigtincan.com>
 */

@import '../../libs/less/tooltips.less';
@import '../../libs/less/utilities.less';
@import '../../libs/less/variables.less';

.EventItem {
  display: block;
  overflow: hidden;
  position: relative;

  &.isActive {
    background-color: var(--light-base-color);
  }

  > a {
    display: block;
  }
}

/* thumbnail mixin */
.thumbnail() {
  text-align: center;
  background-size: cover;
  background-image: url(../StoryThumb/bg_StoryHeader.svg);
  flex: 0 0 auto;

  &:before {
    color: #fff;
  }
}

/* List Styles */
.listItem {
  align-items: center;
  transition: background @timing-fast linear;

  a {
    display: flex;
    align-items: center;
    color: @primary-text;
    padding: 0.5rem;

    &:hover {
      text-decoration: none;
      background-color: @light-base-color;
    }
  }

  .thumbnail {
    .thumbnail();
    margin-right: 0.5rem;

    &:before {
      font-size: 32px;
      line-height: 1.45;
    }
  }
}

/* List Sizes */
.listItemLarge {
  .name {
    -webkit-line-clamp: 3;
    max-height: 4.2rem;
  }
  .note {
    -webkit-line-clamp: 2;
    max-height: 4.2rem;
  }
}

.listItemMedium {
  .name {
    max-height: 2.75rem;
  }
}

.listItemSmall {
  .name {
    -webkit-line-clamp: 1;
    max-height: 1.35rem;
  }
}

/* Grid Styles */
.gridItem {
  display: inline-block;
  margin: 1rem;
  margin-top: 0;
  vertical-align: top;

  .thumbnail {
    .thumbnail();

    &:before {
      font-size: 128px;
      line-height: 1.5;
    }
  }
}

/* Grid Sizes */
.gridItemLarge {
  .name {
    -webkit-line-clamp: 1;
    max-height: 1.35rem;
  }
}

.gridItemMedium {
  margin-bottom: 0.75rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;

  .name {
    -webkit-line-clamp: 1;
    max-height: 1.35rem;
  }
}

.gridItemSmall {
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  overflow: visible; // required for tooltip
  .tooltip(n);

  &:after {
    max-width: 20rem;
    .overflow-ellipsis();
  }
}

.name {
  color: @primary-text;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.info {
  flex: 1 1 auto;
  overflow: hidden;
}

.note {
  color: @secondary-text;
  font-size: 90%;
  display: block;
  text-transform: capitalize;
  .overflow-ellipsis();
}
