/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Jason Huang <jason.huang@bigtincan.com>
 */

@import '../../../libs/less/btc-font-mixins.less';
@import '../../../libs/less/variables.less';

@iconSize: 20px;

.UserList {
  display: flex;
  color: white;
  flex-direction: column;
}

.listItem {
  .icon-circle-fill(10px);
  padding: 0.5rem 1.2rem;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:before {
    color: lawngreen;
  }

  span {
    padding-left: 1rem;
  }
}
