/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/variables.less';
@import '../../libs/less/utilities.less';

.RevisionItem {
  display: block;
  position: relative;
  transition: opacity @timing-fast linear;

  &:after {
    position: absolute;
    bottom: -5px;
    left: 8.8rem;
    content: '';
    width: 2px;
    height: 10px;
    background-color: @divider-color;
    z-index: 1;
  }

  a {
    display: flex;
    align-items: center;
    color: @primary-text;
    padding: 0.6rem 1rem;

    &:hover {
      opacity: 0.8;
    }
  }
}

.originalItem {
  &:after {
    height: 20px;
    bottom: -16px;
  }

  &:before {
    position: absolute;
    bottom: -63px;
    left: 6.4rem;
    content: attr(data-published-text);
    color: @secondary-text;
    background-color: @divider-color;
    border-radius: 2px;
    text-transform: uppercase;
    padding: 0 0.25rem;
    font-size: 90%;
    font-weight: 700;
    margin-bottom: 1.25rem;
  }
}

.thumbnail {
  text-align: center;
  background-color: #f5f5f5;
  background-image: url(../StoryThumb/bg_StoryHeader.svg);
  margin-right: 0.5rem;
  flex: 0 0 auto;

  &:before {
    color: #fff;
    font-size: 32px;
    line-height: 1.45;
  }
}

.time {
  color: @secondary-text;
  margin-right: 1rem;
  width: 5.4rem;
}

.name {
  color: @primary-text;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.note {
  color: @secondary-text;
  font-size: 90%;
  display: block;
  text-transform: capitalize;
  .overflow-ellipsis();
}
