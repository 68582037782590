/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/utilities.less';
@import '../../libs/less/variables.less';

.Breadcrumbs {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pathItem {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: bold;
  white-space: nowrap;
  vertical-align: bottom;
  color: var(--secondary-text);

  &:not(:first-child) {
    max-width: 72%;
    .overflow-ellipsis();

    &:before {
      content: '\203A';
      padding: 0 0.45rem;
    }
  }
}

&.noLinkItem {
  color: var(--base-color);
  cursor: pointer;
}

.viewOnly {
  font-weight: normal;
}
