/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2020 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */
@import '../../libs/less/variables.less';
@import '../../libs/less/btc-font-mixins.less';

.UserActions {
  text-align: center;
  margin-right: 0.8rem;
  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;

    > li {
      color: var(--secondary-text);
      padding: 0 0.65rem;
      margin: 0;
      cursor: pointer;

      &:before {
        color: var(--base-color);
        vertical-align: middle;
      }
    }
  }
}
.onlyActionList {
  > ul {
    margin: 0 !important;
  }
}
.chatBtn {
  a {
    .icon-comment(1.35rem);
    display: block;

    &:before {
      vertical-align: middle;
    }
  }
}

.emailBtn {
  .icon-email(1.35rem);
}

.callBtn {
  .icon-phone(1.35rem);
}

.editBtn {
  .icon-pencil-edit(1rem);
  padding-right: 0 !important;
}

.unlinkBtn {
  .icon-unlink(1.05rem);
  padding-right: 0 !important;
}
.settingsBtn {
  //.icon-pencil-edit(1.05rem);
  padding-right: 0 !important;

  > div {
    padding-top: 0.18rem;
  }
}
.settingsDropMenu {
  // submenu position
  > div > div {
    top: 2rem !important;
    text-align: left;

    > ul > li {
      border-bottom: 1px solid;
      border-color: var(--divider-color);
      padding: 0.55rem 0.75rem;
    }
  }
  // plus icon
  span:before {
    margin-right: 0.25rem;
    font-size: 1.1rem !important;
    cursor: pointer;
    vertical-align: top;
  }
}

.bulkBtn {
  .icon-user-upload(1.2rem);
  padding-left: 1.05rem !important;
  padding-right: 0 !important;
}
.plusBtn {
  .icon-plus(1rem);
  padding-right: 0 !important;
}
.trashBtn {
  .icon-trash(1.3rem);
  padding: 0 1.2rem 0rem 0.85rem !important; // keep align under info icon
}
.tickBtn {
  .icon-tick(1rem);
  padding-right: 0 !important;
}

.dropdownArrow {
  padding: 0 0.3rem !important;
  > div > div > span:before {
    font-size: 0.6rem;
  }
}

.deleteBtn {
  .icon-zoomIn(1rem);
  &:before {
    color: @errorColor;
    transform: rotate(45deg);
  }
}
.shareBtn {
  .icon-shareuser(1.625rem);
  padding-right: 0 !important;
  &:before {
    color: var(--secondary-text) !important;
  }
}
