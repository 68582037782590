/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@barHeight: 3px;
@ballWidth: 14px;

.ProgressControl {
  flex: 1 1 auto;
  padding: 0.5rem 0;
  cursor: pointer;
}

.isDragging {
  cursor: grabbing !important;
}

.progress {
  position: relative;
  height: @barHeight;
  width: 100%;
  vertical-align: middle;
  border-radius: 3px;
}

.bufferBar,
.elapsedBar {
  display: block;
  height: 100%;
  border-radius: 3px;
  position: absolute;
}

.elapsedBall {
  position: absolute;
  top: -5px;
  left: 0;
  width: @ballWidth;
  height: @ballWidth;
  transform: translate(-@ballWidth / 2, 0);
  border-radius: 50%;
  cursor: grab;
}
