.container {
    height: 100%;
    width: 100%;
    .header {
        height: 39px;
        background-color: #f8f8f8;
        width: 100%;
        position: relative;
        margin: 0;
        padding: .5rem 0;
        text-align: center;
        flex: 1 1 auto;
        transition: background .25s ease-out;
        border: 1px solid #ddd;
        border-color: #dddddd;
        border-color: var(--divider-color);
        background-color: #f8f8f8;
        .title {
            text-align: center;
            font-size: .9rem;
        }
    }
    .viewercontainer {
        height: ~"calc(100% - 41px)";
        width: 100%;
        position: relative;
        .center {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}