@import "../../../assets/style-guide/src/libs/less/btc-font-mixins.less";
@import "../../../assets/style-guide/src/libs/less/variables.less";
.log_email_to_salesfo {
  margin-top: 10px;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  .title {
    height: 18px;
    font-size: 13px;
    text-align: left;
    padding-left: 10px;
    color: #222222;
  }
  .icon {
    height: 22px;
    object-fit: contain;
    padding-right: 10px;
    &:before {
      content: "";
      width: 25px;
      height: 25px;
      object-fit: contain;
      background-image: url(../../../static/img/opportunity-icon@2x.png);
      background-size: 100%;
      background-repeat: no-repeat;
      display: block;
    }
  }
  .cmdcontainer {
    margin-left: auto;
    width: 60px;
    display: flex;
    justify-content: space-between;
  }
  .active {
    &:before {
      color: @base-color !important;
    }
  }
  .search {
    cursor: pointer;
    &:before {
      width: 22px;
      height: 22px;
      content: "\f173";
      color: #888888;
      font-family: "btc-font";
      font-size: 18px;
      margin-right: 0px;
    }
  }
  .opp_plus {
    &:before {
      width: 22px;
      height: 22px;
      content: "\f1d0";
      color: @base-color;
      font-family: "btc-font";
      font-size: 18px;
      margin-right: 10px;
    }
  }
}

.oppbody {
  width: 100%;
  max-height: 300px;
  background-color: #ffffff;
  overflow: scroll;
  padding-bottom: 0px;
}

.crmErrorBlock {
  height: 80px;
  .title {
    width: 100%;
    text-align: center;
    margin-top: 20px;

    font-size: 14px;
    font-weight: 600;
    line-height: 1.46;
    letter-spacing: 0.1px;
    color: #222222;
  }
}
.loadingcrmblock {
  height: 54px;
  background-color: #ffffff;
  position: relative;
}

.loadingBlock {
  width: 100%;
  position: relative;
  .title,
  .error {
    width: 100%;
    text-align: center;
    margin-top: 15px;
    font-size: 12px;
    line-height: 1.42;
    letter-spacing: 0.1px;
    text-align: center;
  }
  .subtitle {
    width: 100%;
    text-align: center;
    font-size: 10px;
    line-height: 1.7;
    letter-spacing: 0.1px;
    text-align: center;
    margin-bottom: 5px;
  }
  .loading {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .error {
    color: #dd0000;
  }
}

.searchcontainer {
  height: 40px;
  margin-top: 13px;
  margin-bottom: 5px;
  width: 100%;
  .searchBlock {
    width: 100%;
    height: 35px;
    display: flex;
    border-radius: 0px;
    .input {
      width: 100%;
      background-color: #f5f5f5;
      flex: 1;
      border: 1px;
      font-size: 13px;
      text-align: left;
      color: #777777;
      width: 100%;
      padding-left: 10px;
      border-radius: 3px;
      &:focus {
        outline: none;
      }
    }
    .icon {
      border-radius: 3px;
      background-color: #f5f5f5;
      width: 30px;
      cursor: pointer;
      &:before {
        content: "\f173";
        color: @base-color;
        font-family: "btc-font";
        font-size: 18px;
      }
    }
    .close {
      background-color: #f5f5f5;
      padding: 4px;
      width: 30px;
      padding-left: 10px;
      cursor: pointer;
      &:before {
        content: "\f110";
        color: #777777;
        font-family: "btc-font";
        font-size: 13px;
      }
    }
  }
}

.selectedItem {
  background-color: rgba(242, 103, 36, 0.1);
}

.onsearchfocus {
  border-bottom: solid 2px rgba(240, 123, 73, 0.7);
}
.input:focus::placeholder {
  transform: translate(0, 5%);
}
.compose {
  max-height: 100% !important;
}
