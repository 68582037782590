/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2020 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../../libs/less/variables.less';
@import '../../../libs/less/btc-font-mixins.less';
@import '../../../libs/less/utilities.less';

@containerWidth: 210px;
@containerHeight: 100%;
@findBoxHeight: 70px;

@pagesBg: #f8f8f8;
@pagesBorder: #ccc;
@searchIconColor: #ddd;

@pagesBg-dark: @primary-text;

.PdfFind {
  width: @containerWidth;
  min-width: @containerWidth;
  height: 100%;
  float: left;
  z-index: 1;
  overflow: hidden;
  background-color: #f8f8f8;
  border-right: solid 1px @dividerColor;
  border-right-color: var(--divider-color);
  padding-top: 1.25rem;
}
.pdfFindHeader {
  height: 1.375rem;
  display: flex;
  padding: 0 0.625rem;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.625rem;
  .pdfFindCloseBtn {
    &::before {
      color: var(--base-color);
    }
  }
}

.PdfFindBox {
  background-color: #eee;
  width: calc(@containerWidth - 20px);
  margin: 0 0.625rem 1.25rem 0.625rem;
  border-radius: 0.25rem;
  div {
    width: 100%;
    border-radius: 0.25rem;
    &::before {
      color: var(--base-color);
    }
  }
  input {
    margin: 0 !important;
  }
}

.PdfFoundList {
  margin: @findBoxHeight 0px 0 0px;
  height: calc(~'100%' - @findBoxHeight);
  overflow: auto;
  padding: 0 20px;
  list-style: none;
  color: @primary-text;
  /* nested list */
  ul {
    display: none;
    margin: 0 0 0 1.5rem;
    padding: 0;
    list-style: none;
  }
}

.FoundItem {
  cursor: pointer;
  margin: 0;
  padding: 0 0 30px 0;
  white-space: no-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &:after {
    .icon-next(10px);
  }
}

.FoundTitle {
  font-weight: normal;
  font-size: 12px;
}

.PdfStartSearchMsg {
  position: absolute;
  top: @findBoxHeight;
  height: calc(~'90%' - @findBoxHeight);
  width: @containerWidth;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  > p {
    margin-top: 1rem;
  }
}

.PdfSearchIcon {
  color: @searchIconColor;
  display: block;
}
