/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package style-guide
 * @copyright 2010-2018 BigTinCan Mobile Pty Ltd
 * @author Lochlan McBride <lochlan.mcbride@bigtincan.com>
 */

@import '../../libs/less/variables.less';

.Blankslate {
  position: relative;
  padding: 1rem;
  text-align: center;
  color: #777;
  color: var(--secondary-text);
  width: 100%;

  /* icons */
  > span {
    color: #ddd;
    color: var(--divider-color);

    /* adjacent icon spacing */
    & + span {
      margin-left: 1rem;
    }
  }

  /* node icon */
  .iconNode > * {
    margin-top: 1rem;
    width: 13rem;
  }

  .content {
    h3 {
      margin-top: 1rem;
      margin-bottom: 0;
    }
    p {
      margin-top: 0.25rem;
      margin-bottom: 0.8rem;
      word-break: break-word;
    }
  }

  .message {
    margin: 0.5rem auto;
    font-size: 0.75rem;
  }

  &.iconSize-144 {
    .message {
      margin-top: 1rem;
    }
  }
}

/* Variations */
.inline {
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    display: inline-block;
    margin-right: 1.5rem;
  }

  .content {
    display: inline-block;
    text-align: left;

    h3 {
      margin-top: -0.25rem;
    }
  }

  .message {
    margin: 0.35rem auto;
  }
}

.spacious {
  padding: 6rem 4rem;
}

/* vertical align to middle */
.middle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  flex: 1 1 auto;
  height: 100%;
}

/* error message */
.error {
  > span {
    //color:#f00;
  }
}
