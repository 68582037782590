.slidecontainer{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    position: relative;
    .item{
        height: 33.3%;
        position: relative;
        margin-top: 0px;
        
    }
}
.attach_hub_files_to {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	width: 260px;
	height: 64px;
	
	font-size: 20px;
	line-height: 1.6;
	letter-spacing: 0.2px;
	text-align: center;
	color: #222222;
}
.attach_hub_files_desc {
	width: 260px;
	height: 66px;
	
	font-size: 16px;
	line-height: 1.38;
	letter-spacing: 0.2px;
	text-align: center;
    color: #777777;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
:global{
    .centerimage{
        width: 195px;
        height: 97px;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}