.container_fluid {
  height: 100%;
  width: 100%;
}

.signcontainer {
  width: 100%;
  height: 200px;
}

.choose_a_sign_in_met {
  width: 260px;
  height: 32px;
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: 0.2px;
  text-align: center;
  color: #222222;
  margin: auto;
  display: block;
  margin-bottom: 30px;
}

.topcontainer {
  top: 230px;
}

.itemblock {
  width: 100%;
  height: 79px;
  background-color: #ffffff;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #dddddd;
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding: 0px 30px;
  margin-top: 1px;
  flex-wrap: wrap;
  &:hover {
    background-color: rgba(242, 103, 36, 0.1);
  }
}

.customlogin {
  margin-top: 11px;
}

.userimageblock {
  width: 33px;
  height: 34px;
  object-fit: contain;
  display: inline-block;
  background-image: url(../../../static/img/user-icon@3x.png);
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 23px;
}

.customdomainblock {
  width: 33px;
  height: 34px;
  object-fit: contain;
  background-image: url(../../../static/img/company-icon@2x.png);
  display: inline-block;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 23px;
}

.titlecontainer {
  margin-top: 23px;
  margin-left: 10px;
  display: flex;
  flex-flow: column;
  flex-grow: 1;
}

.title {
  height: 20px;
  font-size: 15px;
  text-align: left;
  color: #222222;
}

.subtitle {
  height: 18px;
  font-size: 13px;
  text-align: left;
  color: #777777;
}

.hublogincontainer {
  width: 100%;
}

.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:before {
    content: "\F165";
    color: #f07b49;
    width: 4.8px;
    height: 8px;
    font-family: "btc-font";
    display: block;
    font-size: 8px;
    line-height: 10px;
  }
  .navbarback {
    padding-left: 5.2px;
  }
}

.back {
  height: 22px;
  font-size: 15px;
  line-height: 1.47;
  letter-spacing: 0.3px;
  text-align: right;
  color: #f07b49;
  cursor: pointer;
}

.regionselection {
  margin-top: 27px;
  display: flex;
  flex-direction: row;
  .regionblock {
    width: 33%;
    text-align: center;
    height: 160px;
    position: relative;
    img {
      width: 60px;
      height: 84px;
      object-fit: contain;
    }
  }
}

.select_your_region {
  margin-top: 40px;
  height: 32px;
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: 0.2px;
  text-align: center;
  color: #222222;
  display: block;
}

.regiontitle {
  margin-top: 16px;
  height: 19px;
  font-size: 14px;
  text-align: center;
  color: #42332e;
  bottom: 0px;
  position: absolute;
  display: block;
  width: 100%;
}

.regionradio {
  margin-top: 16px;
  height: 19px;
  font-size: 14px;
  text-align: center;
  color: #42332e;
  bottom: 30px;
  position: absolute;
  display: block;
  width: 100%;
}

.confirmcontainer {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  width: 100%;
}

.confirmbtn {
  font-size: 15px;
  width: 280px;
  height: 40px !important;
  cursor: pointer;
  border-radius: 3px;
  background-color: #f07b49;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}

@inputBg: #f5f5f5;
.Login {
  display: flex;
  width: 900px;
  height: 450px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  &:focus {
    outline: none;
  }
}

.input {
  background: @inputBg;
  border: solid 1px transparent;
  border-radius: 3px;
  height: 40px;
  border-radius: 3px;
  background-color: #f5f5f5;
  width: 100%;
  display: block;
  margin: 0 0 0.75rem;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  text-align: left;
  color: #777777;
  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
  & + button {
    margin-top: 3.5rem;
  }
  &:focus {
    outline: none;
  }
}

.sign_in_label {
  margin-top: 75px;
  height: 32px;
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: 0.2px;
  text-align: center;
  color: #222222;
  display: block;
}

.error {
  margin-top: 10px;
  height: 32px;
  font-size: 13px;
  line-height: 1.78;
  letter-spacing: 0.2px;
  text-align: center;
  color: #222222;
  display: block;
}

.logincontainer {
  margin-top: 26px;
}

.switchContainer {
  background: @inputBg;
  border: solid 1px transparent;
  border-radius: 3px;
  height: 40px;
  border-radius: 3px;
  background-color: #f5f5f5;
  width: 100%;
  display: block;
  margin: 0 0 0.75rem;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
  text-align: left;
  color: #777777;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
  & + button {
    margin-top: 3.5rem;
  }
  &:focus {
    outline: none;
  }
}

.radiogroup {
  display: flex;
  justify-content: space-between;
  height: 40px;
  border-radius: 3px;
  background-color: #f5f5f5;
  .radiogrouplabel {
    height: 100%;
    font-size: 14px;
    text-align: left;
    color: #222222;
    padding: 0.5rem 0.75rem;
  }
  .radiogroupcontainer {
    height: 100%;
    padding: 0.5rem 0.75rem;
  }
}
.account {
  font-size: 12px;
  text-align: left;
}
