/**
 *
 * BIGTINCAN - CONFIDENTIAL
 *
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of BigTinCan Mobile Pty Ltd and its suppliers,
 * if any. The intellectual and technical concepts contained herein are proprietary to BigTinCan Mobile Pty Ltd and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or
 * copyright law. Dissemination of this information or reproduction of this material is strictly forbidden unless prior
 * written permission is obtained from BigTinCan Mobile Pty Ltd.
 *
 * @package hub-web-app-v5
 * @copyright 2010-2020 BigTinCan Mobile Pty Ltd
 * @author Olivia Mo <olivia.mo@bigtincan.com>
 */

@import '../../libs/less/btc-font-mixins.less';
@import '../../libs/less/utilities.less';
@badgeSpacing: 8px;

.iconsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  color: #fff;
  color: var(--base-text);
  > div {
    padding: 0.3125rem;
    border-radius: 0.5rem;
  }
  &.grid {
    > div {
      padding: 0.625rem 0.625rem 0.5625rem 0.5625rem;
    }
  }

  .gradientTop {
    text-align: right;
    height: 4rem;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 89.84%
    );

    .ratingCount {
      .icon-like(1rem);

      &.isLiked {
        .icon-like-fill(1rem);
      }

      &:before {
        margin-right: 0.28125rem;
      }
    }

    .commentCount {
      .icon-comment-fill(1rem);

      &:before {
        margin-left: 0.875rem;
        margin-right: 0.28125rem;
      }
    }
  }

  .gradientBottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    text-align: left;
    height: 4rem;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );

    .leftIcons {
      > span {
        padding-right: 0.5rem;
        vertical-align: middle;
      }
    }
    .rightIcons {
      > span {
        padding-left: 1.125rem;
      }
    }

    .fileIcon {
      .icon-file-fill(1.25rem);

      &:before {
        margin-right: 0.28125rem;
      }
    }

    .quicklinkIcon {
      .icon-quicklink-fill(1rem);
    }
    .quickfileIcon {
      .icon-file(1rem);
    }
    .locationIcon {
      .icon-location(1rem);
    }
    .protectedIcon {
      .icon-lock(1rem);
    }
    .bookmarkIcon {
      .icon-bookmark-fill(1rem);
    }
  }
}
